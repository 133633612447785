import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cards from "../components/Cards";
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";
import { Link } from "react-router-dom";
import Features from "./../assets/Media/Features.png";
import phone from "./../assets/Media/phone_call_receiver.png";
import Envelope from "./../assets/Media/envelope.png";
import Smartphone from "./../assets/Media/Smartphone_mobile.png";
import Seller from "./../assets/Media/sellerinfofb.png";
import services_portfolio from "./../assets/Media/services_portfolio.png"
import description from "./../assets/Media/description.png"
const ListingDetail = (props) => {
  const [listing, setListing] = useState({});
  const [realtor, setRealtor] = useState({});


  useEffect(() => {
    const id = props.match.params.id;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/realtors/${id}`, config)
        .then((res) => {
          setRealtor(res.data);
        })
        .catch((err) => { });
    }
  }, [listing.realtor]);

  return (
    <div className="listingdetail overflow-x-hidden">
      <Helmet>
        <title>Realest Estate - Listing | {`${listing.title}`}</title>
        <meta name="description" content="Listing detail" />
      </Helmet>


      <div id="left-side" className="flex justify-evenly mt-14">
        <div id="grid" className="xl:flex space-x-4">
          <div id="grid" class="xl:flex xl:space-x-10">
            <div id="plumber-main" class=" xl:ml-[80px] mb-5">
              <div class="mb-7 bg-[#fafafa] lg:w-[800px] xl:mx-0 mx-5">
                <div class="text-area ml-5">
                  <div class="text-[#9d7e2e] text-4xl font-medium tracking-tight mb-1.5">
                    <p>{realtor.name}</p>
                  </div>
                  <div className="relative overflow-hidden">
                    <img
                      src={realtor.photo}
                      alt=""
                      className="object-cover object-center w-full h-full"
                    />
                    <div className="absolute inset-0 bg-black opacity-40"></div>

                  </div>
                </div>
                <div class="border-b border-[#9d7e2e] mb-2"></div>
                <div class="img-area">
                  <img src="../Assets/Media/plumber main.png" alt="" class="w-[900px]" />
                </div>
              </div>


              <div class="plumbing-portfolio bg-[#fafafa] xl:w-[800px] mb-5 xl:mx-0 mx-5">
                <div class="flex space-x-3 pb-2 pl-5 pt-2">
                  <div class="icon">
                    <img src={services_portfolio} alt="" />
                  </div>
                  <div class="text-black font-bold text-xl pt-1">
                    <h4>
                      Portfolio

                    </h4>
                  </div>
                </div>

              </div>

              <div class="together flex space-x-3 mb-5 mx-0">
                <div class="plumbing-features bg-[#fafafa] w-[380px]">
                  <div class="flex space-x-3 pb-[22px] sm:pb-2 pl-5 pt-2">
                    <div>
                      <img src={Features} alt="" />
                    </div>
                    <div class="text-black font-bold text-xl pt-1">
                      <h4>
                        Features
                      </h4>
                    </div>
                  </div>
                  <div class="border-b border-[#9d7e2e] mb-7"></div>
                  <div class="text-area p-5 space-y-2 tracking-wide">
                    <div className="text-area p-4">
                      <div className="flex space-x-4 items-center text-lg mb-4">
                        <p className="font-medium text-gray-700">Agency Email:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agencyemail}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center mb-4">
                        <p className="font-medium text-gray-700">Agency Phone:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agencyphone}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center mb-4">
                        <p className="font-medium text-gray-700">Total Agents:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agentscount}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center">
                        <p className="font-medium text-gray-700">Top Seller:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.top_seller}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="description bg-[#fafafa] w-[410px]">
                  <div class="flex space-x-3 pb-1 pl-5 sm:pt-2">
                    <div>
                      <img src={description} alt="" />
                    </div>
                    <div class="text-black font-bold text-xl pt-2">
                      <h4>About Service Provider</h4>

                    </div>
                  </div>
                  <div class="border-b border-[#9d7e2e] mb-7"></div>
                  <div class="text-area p-5 py-2 tracking-wide">
                    <div className="text-gray-800 leading-relaxed">

                      <p>{realtor.description}</p>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <div>


              <div id="right-side" className="w-full">
                <div>
                  <form className="">
                    <div className="bg-[#f5f5f5] p-10 MB-5 rounded-lg shadow space-y-5 w-[450px] h-[735px]">
                      <div className="text-black text-center text-2xl font-medium tracking-tight">
                        <h2>Agency Information</h2>
                      </div>
                      <div class="plumber-info text-white text-2xl tracking-tight font-medium">
                        <div class="flex justify-center space-x-2 items-center">
                          <img
                            src={realtor.agencylogo}
                            alt=""
                            class=" rounded-full h-16 w-16"
                          />
                          <div class="text-[#9d7e2e] uppercase text-xl ">
                            {realtor.saller_type}
                            <br />{" "}
                            <div>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="links-to-contact">
                        <div class="">
                          <div class="tracking-wide mt-7 mb-3 text-center text-lg font-medium">
                            <p>Contact the Agent</p>
                          </div>
                          <div class="imgs flex justify-center items-center space-x-8">
                            <div>
                              <img src={Envelope} alt="" />
                            </div>
                            <div>
                              <img src={Smartphone} alt="" />
                            </div>
                            <div>
                              <img src={Seller} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="number lg:bg-white text-[#9d7e2e] text-xl mx-auto rounded-full tracking-tight font-medium w-44 p-1 lg:border-[3px] border-[#9d7e2e] hover:underline">
                        <div className="flex space-x-2 justify-center items-center">
                          <img src={phone} alt="" className="w-7" />
                          <button>{realtor.phone}  </button>
                        </div>
                      </div>
                      <div class="w-full relative group">
                        <input
                          type="text"
                          id="username"
                          required
                          class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                        ></input>
                        <label
                          for="username"
                          class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                        >
                          Name
                        </label>
                      </div>
                      <div class="w-full relative group">
                        <input
                          type="text"
                          id="username"
                          required
                          class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                        ></input>
                        <label
                          for="username"
                          class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                        >
                          Email
                        </label>
                      </div>
                      <div class="w-full relative group">
                        <input
                          type="text"
                          id="username"
                          required
                          class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                        ></input>
                        <label
                          for="username"
                          class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                        >
                          Phone
                        </label>
                      </div>
                      <div class="w-full relative group">
                        <textarea
                          type="text"
                          id="username"
                          required
                          class="w-full h-20 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                        ></textarea>
                        <label
                          for="username"
                          class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                        >
                          Message
                        </label>
                      </div>
                      <div className="flex justify-end">
                        <button>
                          <a
                            href="#_"
                            class="relative inline-flex items-center justify-start  py-3 pl-4 pr-12 overflow-hidden font-semibold text-[#60a1f6] transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group"
                          >
                            <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-[#60a1f6] group-hover:h-full"></span>
                            <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                              <svg
                                class="w-5 h-5 text-[#9d7e2e]"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                                ></path>
                              </svg>
                            </span>
                            <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                              <svg
                                class="w-5 h-5 text-[#9d7e2e]"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                                ></path>
                              </svg>
                            </span>
                            <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
                              Submit
                            </span>
                          </a>
                        </button>
                        {/* <button>
                        <a
                          href="#_"
                          class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#60a1f6] border-2 border-gray-200 transition duration-300 ease-out rounded-full shadow-md group"
                        >
                          <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#60a1f6] group-hover:translate-x-0 ease">
                            <svg
                              class="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                              ></path>
                            </svg>
                          </span>
                          <span class="absolute flex items-center justify-center w-full h-full text-[#9d7e2e] transition-all duration-300 transform group-hover:translate-x-full ease">
                            Contact
                          </span>
                          <span class="relative invisible">Contact</span>
                        </a>
                      </button> */}
                      </div>
                    </div>
                  </form>
                </div>

                <div class="for-ads bg-[#60a1f6] border-[2px] w-[450px] h-[420px] uppercase flex justify-center items-center border-blue-500 text-white text-xl tracking-tight mt-5 rounded-lg">
                  <p>space available for ads</p>
                </div>
              </div>


            </div>
          </div>
          {/* <div id="plumber-main" className="">
            <div className="bg-[#fafafa] lg:w-[700px] rounded-lg shadow">
              <div className="flex justify-between text-lg items-center p-3 bg-gray-100 rounded-md">
                <div className="text-[#9d7e2e] text-2xl uppercase font-medium mt-6 md:mt-8 md:text-4xl">
                  <p className="md:mt-4">{realtor.name}</p>
                </div>

                <button className="bg-[#9d7e2e] text-white px-4 py-2 rounded-md hover:bg-yellow-400 mt-4 md:mt-6">
                  {realtor.saller_type}
                </button>

              </div>


              <div className="relative overflow-hidden">
                <img
                  src={realtor.photo}
                  alt=""
                  className="object-cover object-center w-full h-full"
                />
                <div className="absolute inset-0 bg-black opacity-40"></div>

              </div>
              <div className="lg:flex lg:space-x-3 w-full h-auto lg:h-[420px] rounded-lg overflow-hidden shadow-lg">
                <div className="plumbing-featuresb shadow bg-gray-50 rounded-lg w-full lg:w-96">
                  <div className="lg:w-1/2 h-48 lg:h-auto relative">
                    <h1 className="text-2xl uppercase p-4 font-medium text-[#9d7e2e]">
                      Features
                    </h1>
                    <div className="border-b border-[#9d7e2e] mb-7" />
                    <div className="text-area p-4">
                      <div className="flex space-x-4 items-center text-lg mb-4">
                        <p className="font-medium text-gray-700">Agency Email:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agencyemail}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center mb-4">
                        <p className="font-medium text-gray-700">Agency Phone:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agencyphone}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center mb-4">
                        <p className="font-medium text-gray-700">Total Agents:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.agentscount}</p>
                      </div>
                      <div className="flex space-x-4 text-lg items-center">
                        <p className="font-medium text-gray-700">Top Seller:</p>
                        <p className="text-[#9d7e2e] font-medium">{realtor.top_seller}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="description bg-gray-100 shadow-lg w-96 md:w-[350px] rounded-lg p-6">
                  <h1 className="text-2xl text-yellow-800 font-bold uppercase mb-4">
                    About Property
                  </h1>
                  <div className="border-b border-yellow-800 mb-4" />
                  <div className="text-gray-800 leading-relaxed">
                    <p>{listing.description}.</p>
                  </div>
                </div>

              </div>

            </div>




          </div> */}

        </div>
      </div>

      <Cards />
      <Add />
      <GetApp />
    </div>
  );
};

export default ListingDetail;
