import React from 'react';
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";
const PrivacyPolicy = () => {
    return (
        <>
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-6 sm:max-w-4xl sm:mx-auto">
                    <div className="absolute inset-0 bg-[#9d7e2e] from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl"></div>
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <div className="max-w-2xl mx-auto text-center">
                            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Privacy &amp; Policy</h2>
                        </div>
                        <h1 className="text-lg font-semibold mb-3"></h1>
                        {/* <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2 mx-auto max-w-xl"> */}

                        <p className="text-gray-700 mb-4">
                            Your privacy is important to us. It is Affanbaba.com policy to respect your
                            privacy regarding any information we may collect from you across our
                            website, www.affanbaba.com.
                        </p>
                        <p className="text-gray-700 mb-4">
                            We ask for personal information when we truly need it to provide a
                            service to you. We collect it by fair and lawful means, with your
                            knowledge and consent. We also let you know why we’re collecting it and
                            how it will be used. We only retain collected information for as long as
                            necessary to provide you with your requested service. What data we store,
                            we’ll protect within commercially acceptable means to prevent loss and
                            theft, as well as unauthorized access, disclosure, copying, use or
                            modification. We don’t share any personally identifying information
                            publicly or with third-parties, except when required to by law.
                        </p>
                        <p className="text-gray-700 mb-4">
                            Our website may link to external sites that are not operated by us.
                            Please be aware that we have no control over the content and practices
                            of these sites, and cannot accept responsibility or liability for their
                            respective privacy policies.
                        </p>
                        <p className="text-gray-700 mb-4">
                            You are free to refuse our request for your personal information, with
                            the understanding that we may be unable to provide you with some of your
                            desired services. Your continued use of our website will be regarded as
                            acceptance of our practices around privacy and personal information. If
                            you have any questions about how we handle user data and personal
                            information, feel free to contact us.
                        </p>
                        <p className="text-gray-700 mb-4">
                            This Privacy Policy describes how Affanbaba.com handles your personal
                            and non-personal information. We may also collect information about you
                            from other sources. Accordingly, our Privacy Policy may apply to you even
                            if you do not submit information to us through Affanbaba.com
                        </p>
                        <p className="text-gray-700 mb-4">
                            We may also disclose all such data if:
                            <ol className="list-decimal ml-8 mb-4">
                                <li className="mb-2">
                                    required by applicable law
                                </li>
                                <li className="mb-2">
                                    required as part of any merger
                                </li>
                                <li className="mb-2">
                                    required by any service provider in relation to any product we believe is relevant to the user
                                </li>
                            </ol>
                        </p>
                        {/* </div> */}


                    </div>
                </div>

            </div>
            <Add />
            <GetApp />
        </>
    );
}

export default PrivacyPolicy;
