import React, { useState } from "react";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import background from "./../assets/Media/slider.jpeg";

const ListingForm = (props) => {
    const [formData, setFormData] = useState({
        sale_type: "For Sale",
        price: "",
        bedrooms: "",
        home_type: "House",
        bathrooms: "",
        sqft: "",
        keywords: "",
    });

    const { sale_type, price, bedrooms, home_type, bathrooms, sqft, keywords } =
        formData;

    const [loading, setLoading] = useState(false);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        setLoading(true);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/listings/search`,
                { sale_type, price, bedrooms, home_type, bathrooms, sqft, keywords },
                config
            )
            .then((res) => {
                setLoading(false);
                props.setListings(res.data);
                window.scrollTo(0, 0);
            })
            .catch((err) => {
                setLoading(false);
                window.scrollTo(0, 0);
            });
    };

    return (
        // <form className='listingform' onSubmit={e => onSubmit(e)}>
        //     <div className='grid grid-cols-6 gap-4'>
        //         <div className='col-span-1'>
        //             <div className='listingform__section'>
        //                 <label className='listingform__label' htmlFor='sale_type'>Sale or Rent</label>
        //                 <select className='listingform__select' name='sale_type' onChange={e => onChange(e)} value={sale_type}>
        //                     <option>For Sale</option>
        //                     <option>For Rent</option>
        //                 </select>
        //             </div>
        //         </div>
        //         <div className='col-span-1'>
        //             <div className='listingform__section'>
        //                 <label className='listingform__label' htmlFor='price'>Minimum Price</label>
        //                 <select className='listingform__select' name='price' onChange={e => onChange(e)} value={price}>
        //                     <option>$0+</option>
        //                     <option>$200,000+</option>
        //                     <option>$400,000+</option>
        //                     <option>Any</option>
        //                 </select>
        //             </div>
        //         </div>
        //         <div className='col-span-1'>
        //             <div className='listingform__section'>
        //                 <label className='listingform__label' htmlFor='bedrooms'>Bedrooms</label>
        //                 <select className='listingform__select' name='bedrooms' onChange={e => onChange(e)} value={bedrooms}>
        //                     <option>0+</option>
        //                     <option>1+</option>
        //                     <option>2+</option>
        //                     <option>3+</option>
        //                     <option>4+</option>
        //                     <option>5+</option>
        //                 </select>
        //             </div>
        //         </div>
        //         <div className='col-span-3'>
        //             <div className='listingform__section'>
        //                 <label className='listingform__label' htmlFor='keywords'>Keywords</label>
        //                 <input className='listingform__input' name='keywords' type='text' onChange={e => onChange(e)} value={keywords} />
        //             </div>
        //         </div>
        //         <div className='col-span-1'>
        //             {loading ?
        //                 <div className='listingform__loader'>
        //                     <TailSpin
        //                         type="Oval"
        //                         color="#424242"
        //                         height={50}
        //                         width={50}
        //                     />
        //                 </div> :
        //                 <button className='listingform__button listingform__button--primary'>Save</button>
        //             }
        //         </div>
        //     </div>
        // </form>

        <div
            className="slider h-[559px] flex justify-center items-center"
            style={{ backgroundImage: `url(${background})` }}
        >
            <form class="listingform p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 bg-gray-100 shadow-lg rounded-lg" onSubmit={(e) => onSubmit(e)}>


                <div class="flex justify-between bg-[#9d7e2e] rounded-lg shadow-md px-4 py-2.5 md:px-6 md:py-4">
                    <label for="simple-search" class="sr-only">
                        Keywords
                    </label>
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                        <input
                            type="text"
                            id="simple-search"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-[#9d7e2e]  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Keywords"
                            required
                        />
                    </div>

                </div>

                <div class="flex justify-between bg-white rounded-lg shadow-md px-4 py-2.5 md:px-6 md:py-4">
                    <div class="flex">
                        <div class="flex-1">
                            <label for="sale_type" class="block text-lg font-bold text-[#9d7e2e]  mb-2">
                                Sale or Rent
                            </label>
                            <div class="relative inline-flex items-center bg-gray-200 rounded-full px-6 py-2">
                                <select id="sale_type" name="sale_type" class="bg-transparent appearance-none outline-none text-[#9d7e2e]  pr-6">
                                    <option value="sale">For Sale</option>
                                    <option value="rent">For Rent</option>
                                </select>
                                <svg class="w-4 h-4 absolute top-1/2 right-3 transform -translate-y-1/2 fill-current text-[#9d7e2e] " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M10 12l-6-6h12l-6 6z" />
                                </svg>
                            </div>
                        </div>
                        <div class="flex-1 md:mx-4">
                            <label for="price" class="block text-sm font-medium text-[#9d7e2e]  mb-2 md:mb-0 md:w-1/3">
                                <span class="inline-block bg-[#9d7e2e] rounded-full px-2 py-1 text-xs font-bold text-white mr-2 md:mr-0 md:ml-2">Price</span>
                            </label>
                            <div class="relative mt-1">
                                <select id="price" name="price"
                                    class="form-select block w-full pr-10 py-2.5 text-[#9d7e2e] border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-[#9d7e2e]  dark:text-white dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 transition duration-300 ease-in-out">

                                    <option class="py-2">0</option>
                                    <option class="py-2">100,000</option>
                                    <option class="py-2">200,000</option>
                                    <option class="py-2">400,000</option>
                                    <option class="py-2">500,000</option>
                                    <option class="py-2">600,000</option>
                                    <option class="py-2">Any</option>
                                </select>

                            </div>
                        </div>

                        <div class="flex-1 md:mx-4">
                            <label for="price" class="block text-sm font-medium text-[#9d7e2e]  mb-2 md:mb-0 md:w-1/3">
                                <span class="inline-block bg-[#9d7e2e] rounded-full px-2 py-1 text-xs font-bold text-white mr-2 md:mr-0 md:ml-2">Bedrooms</span>
                            </label>
                            <div class="relative mt-1">
                                <select id="price" name="price"
                                    class="form-select block w-full pr-10 py-2.5 text-[#9d7e2e] border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-[#9d7e2e]  dark:text-white dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 transition duration-300 ease-in-out">

                                    <option class="py-2">0</option>
                                    <option class="py-2">1</option>
                                    <option class="py-2">2</option>
                                    <option class="py-2">3</option>
                                    <option class="py-2">4</option>
                                    <option class="py-2">5</option>
                                    <option class="py-2">Any</option>
                                </select>

                            </div>
                        </div>
                        <div class="flex-1 md:mx-4">

                            <label for="price" class="block text-sm font-medium text-[#9d7e2e]  mb-2 md:mb-0 md:w-1/3">
                                <span class="inline-block bg-[#9d7e2e] rounded-full px-2 py-1 text-xs font-bold text-white mr-2 md:mr-0 md:ml-2"> BathRooms</span>

                            </label>

                            <div class="relative mt-1">
                                <select id="price" name="price"
                                    class="form-select block w-full pr-10 py-2.5 text-[#9d7e2e] border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-[#9d7e2e]  dark:text-white dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 transition duration-300 ease-in-out">

                                    <option class="py-2">0</option>

                                    <option class="py-2">1</option>
                                    <option class="py-2">2</option>
                                    <option class="py-2">3</option>
                                    <option class="py-2">4</option>
                                    <option class="py-2">5</option>
                                    <option class="py-2">Any</option>
                                </select>

                            </div>
                        </div>
                        <div class="flex-1 md:mx-4">
                            <label for="price" class="block text-sm font-medium text-[#9d7e2e]  mb-2 md:mb-0 md:w-1/3">
                                <span class="inline-block bg-[#9d7e2e] rounded-full px-2 py-1 text-xs font-bold text-white mr-2 md:mr-0 md:ml-2">HomeType</span>
                            </label>
                            <div class="relative mt-1">
                                <select id="price" name="price"
                                    class="form-select block w-full pr-10 py-2.5 text-[#9d7e2e] border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-[#9d7e2e]  dark:text-white dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 transition duration-300 ease-in-out">

                                    <option class="House">House</option>
                                    <option class="PLOT">PLOT</option>
                                    <option class="FLAT">FLAT</option>
                                    <option class="COMMERCIAL">COMMERCIAL</option>
                                    <option class="PROJECTS">PROJECTS</option>
                                </select>

                            </div>
                        </div>

                        <div class="flex-1 md:mx-4">


                            <label for="price" class="block text-sm font-medium text-[#9d7e2e]  mb-2 md:mb-0 md:w-1/3">
                                <span class="inline-block bg-[#9d7e2e] rounded-full px-2 py-1 text-xs font-bold text-white mr-2 md:mr-0 md:ml-2"> Count</span>

                            </label>

                            <div class="relative mt-1">
                                <select id="price" name="price"
                                    class="form-select block w-full pr-10 py-2.5 text-[#9d7e2e] border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-[#9d7e2e]  dark:text-white dark:border-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 transition duration-300 ease-in-out">

                                    <option class="py-2">100,000</option>
                                    <option class="py-2">200,000</option>
                                    <option class="py-2">300,000</option>
                                    <option class="py-2">400,000</option>
                                    <option class="py-2">500,000</option>
                                    <option class="py-2">700,000</option>
                                    <option class="py-2">800,000</option>
                                    <option class="py-2">900,000</option>

                                    <option class="py-2">Any</option>
                                </select>

                            </div>
                        </div>



                    </div>
                    <div className="col-span-1">
                        {loading ? (
                            <div className="listingform__loader">
                                <TailSpin type="Oval" color="#424242" height={50} width={50} />
                            </div>
                        ) : (
                            <button
                                type="button"
                                className="p-3 ml-3 text-sm font-medium text-white bg-gradient-to-r from-[#9d7e2e] to-yellow-600 rounded-lg border border-blue-700 hover:bg-yellow-600 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={() => document.querySelector(".filter-section").classList.toggle("hidden")}
                            >
                                <svg
                                    className="w-5 h-5"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </button>
                        )}
                    </div>

                </div>

            </form>
        </div>
    );
};

ListingForm.propTypes = {
    setListings: PropTypes.func.isRequired,
};

export default ListingForm;
