// import React, { useState } from "react";
// import { connect } from "react-redux";
// import { Link, Redirect } from "react-router-dom";
// import { Helmet } from "react-helmet";
// import { setAlert } from "../actions/alert";
// import { signup } from "../actions/auth";
// import PropTypes from "prop-types";
// import phone from "./../assets/Media/footer mobile_phones (1).png";

// const SignUp = ({ setAlert, signup, isAuthenticated }) => {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     re_password: "",
//     is_realtor: "True",
//   });

//   const { name, email, password, re_password, is_realtor } = formData;
//   const onChange = (e) =>
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   const onSubmit = (e) => {
//     e.preventDefault();

//     if (password !== re_password) setAlert("Passwords do not match", "error");
//     else signup({ name, email, password, re_password, is_realtor });
//   };

//   if (isAuthenticated) return <Redirect to="/" />;

//   return (
//     <div className=" min-h-screen  bg-gray-200 flex items-center justify-center px-5 py-5">
//       <div
//         className="bg-white text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
//         style={{ maxWidth: "1000px" }}
//       >
//         <div className="md:flex w-full">
//           <div className="hidden md:block w-1/2 bg-[#9d7e2e] py-10 px-10">
//             <div class="hidden lg:block">
//               <div class=" ">
//                 <img class="h-96 m-auto" src={phone} alt="" />
//               </div>
//             </div>
//           </div>
//           <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
//             <div className="text-center mb-10">
//               <h1 className="font-bold text-3xl text-gray-900">REGISTER</h1>
//               <p>Enter your credentials to register</p>
//             </div>
//             <div>
//               <form className="auth__form" onSubmit={(e) => onSubmit(e)}>
//                 <div className="mb-5 mr-14">
//                   <label htmlFor className="text-xs font-semibold px-1">
//                     Full Name
//                   </label>
//                   <div className="flex">
//                     <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
//                       <i className="mdi mdi-account-outline text-gray-400 text-lg" />
//                     </div>
//                     <input
//                       type="text"
//                       className="w-full -ml-10 pl-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#9d7e2e]"
//                       placeholder="Enter your Full Name"
//                       name="name"
//                       value={name}
//                       onChange={(e) => onChange(e)}
//                       required
//                     />
//                   </div>
//                 </div>
//                 {/* <div className="w-1/2 px-3 mb-5">
//                                     <label htmlFor className="text-xs font-semibold px-1">Last name</label>
//                                     <div className="flex">
//                                         <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"><i className="mdi mdi-account-outline text-gray-400 text-lg" /></div>
//                                         <input type="text" className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#9d7e2e]" placeholder="Smith" />
//                                     </div>
//                                 </div> */}
//                 <div className="mb-5 mr-14">
//                   <label htmlFor className="text-xs font-semibold px-1">
//                     Email
//                   </label>
//                   <div className="flex">
//                     <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
//                       <i className="mdi mdi-email-outline text-gray-400 text-lg" />
//                     </div>
//                     <input
//                       type="email"
//                       className="w-full -ml-10 pl-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#9d7e2e]"
//                       placeholder="Enter your Email"
//                       name="email"
//                       value={email}
//                       onChange={(e) => onChange(e)}
//                       required
//                     />
//                   </div>
//                 </div>
//                 <div className="flex -mx-3">
//                   <div className="w-full px-3 mb-12">
//                     <label htmlFor className="text-xs font-semibold px-1">
//                       Password
//                     </label>
//                     <div className="flex">
//                       <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
//                         <i className="mdi mdi-lock-outline text-gray-400 text-lg" />
//                       </div>
//                       <input
//                         type="password"
//                         className="w-full -ml-10 pl-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#9d7e2e]"
//                         placeholder="Password"
//                         name="password"
//                         value={password}
//                         onChange={(e) => onChange(e)}
//                         minLength="6"
//                       />
//                     </div>
//                   </div>

//                   <div className="w-full px-3 mb-12">
//                     <label htmlFor className="text-xs font-semibold px-1">
//                       Confirm Password
//                     </label>
//                     <div className="flex">
//                       <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
//                         <i className="mdi mdi-lock-outline text-gray-400 text-lg" />
//                       </div>
//                       <input
//                         type="password"
//                         className="w-full -ml-10 pl-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-[#9d7e2e]"
//                         placeholder="Confirm Password"
//                         name="re_password"
//                         value={re_password}
//                         onChange={(e) => onChange(e)}
//                         minLength="6"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="flex -mx-3">
//                   <div className="w-full px-3 mb-5">
//                     <button className="block w-full max-w-xs mx-auto bg-[#9d7e2e] hover:bg-blue-400 active:bg-blue-300 text-white rounded-lg px-3 py-3 font-semibold">
//                       REGISTER NOW
//                     </button>
//                   </div>
//                 </div>
//               </form>
//               <p className="auth__authtext text-center">
//                 Already have an account?{" "}
//                 <Link
//                   className="text-xs font-semibold hover:underline text-[#9d7e2e]"
//                   to="/login"
//                 >
//                   Sign In
//                 </Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// SignUp.propTypes = {
//   setAlert: PropTypes.func.isRequired,
//   signup: PropTypes.func.isRequired,
//   isAuthenticated: PropTypes.bool,
// };

// const mapStateToProps = (state) => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });

// export default connect(mapStateToProps, { setAlert, signup })(SignUp);

// import React, { useState } from "react";


import { Link } from "react-router-dom";

import phone from "./../assets/Media/footer mobile_phones (1).png";
import React, { useState } from "react";
import axios from "axios";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    re_password: "",
    is_realtor: "False",
  });

  const [error, setError] = useState("");

  const { name, email, password, re_password, is_realtor } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormData({
      name: "",
      email: "",
      password: "",
      re_password: "",
      is_realtor: "False",
    });
    try {
      const res = await axios.post(
        "https://affanbaba.com/auth/user/register",
        formData
      );
      console.log(res.data);
      if (formData.is_realtor === "True") {
        // redirect to detail page
        window.location.href = "/AddRealtors";
      } else {
        // redirect to home page
        window.location.href = "/";
      }
    } catch (err) {
      console.error(err);
      setError("Registration failed. Please try again.");
    }
  };





  return (


    <div className=" min-h-screen  bg-gray-200 flex items-center justify-center px-5 py-5">
      <div
        className="bg-white text-gray-500 rounded-3xl shadow-xl w-full overflow-hidden"
        style={{ maxWidth: "1000px" }}
      >
        <div className="md:flex w-full">
          <div className="hidden md:block w-1/2 bg-[#9d7e2e] py-10 px-10">
            <div class="hidden lg:block">
              <div class=" ">
                <img class="h-96 m-auto" src={phone} alt="" />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
            <div className="text-center mb-10">

              <p class="block max-w-sm p-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"><h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">REGISTER </h5></p>

            </div>

            <div>
              <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
                {error && <div className="text-red-500">{error}</div>}

                <div className="mb-4 relative w-full">
                  <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    className="bg-gray-50 border border-[#9d7e2e] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-4 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-4 relative w-full">
                  <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="bg-gray-50 border border-[#9d7e2e] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-4 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
                <div className="mb-4 relative w-full">
                  <label htmlFor="password" className="block text-gray-700 font-bold mb-2 sr-only">
                    Password
                  </label>

                  <input
                    type="password"
                    placeholder="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={handleChange}
                    className="bg-gray-50 border border-[#9d7e2e] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-2.5 px-4 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-4 relative w-full">
                  <label htmlFor="re_password" className="block text-gray-700 font-bold mb-2">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    id="re_password"
                    placeholder="Confirm Password"
                    name="re_password"
                    value={re_password}
                    onChange={handleChange}
                    className="bg-gray-50 border border-[#9d7e2e] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>

                <div className="mb-6">
                  <label htmlFor="is_realtor" className="inline-flex items-center">
                    <input
                      type="checkbox"
                      id="is_realtor"
                      name="is_realtor"
                      checked={is_realtor === "True"}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          is_realtor: e.target.checked ? "True" : "False",
                        })
                      }
                      className="form-checkbox h-5 w-5 text-gray-600"
                    />
                    <span className="ml-2 text-gray-700 font-bold">Are you a realtor?</span>
                  </label>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-[#9d7e2e] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  >
                    Register
                  </button>
                </div>
              </form>

              <p className="auth__authtext text-center">
                Already have an account?{" "}
                <Link
                  className="text-xs font-semibold hover:underline text-[#9d7e2e]"
                  to="/login"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
