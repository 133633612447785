import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import axios from 'axios';
import { Link, NavLink } from 'react-router-dom';

function App() {
  const [listing, setListings] = useState([]);

  // const getdata = async () => {
  //   fetch("https://affanbaba.com/api/listing/get-listings")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setListing(data.listings);
  //     });
  // };
  // useEffect(() => {
  //   getdata();
  // }, []);


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/listings/`);

        setListings(res.data.results);
        // setCount(res.data.count);
        // setPrevious(res.data.previous);
        // setNext(res.data.next);
      }
      catch (err) {

      }
    }

    fetchData();
  }, []);




  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 435;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 435;
  };

  return (
    <>
      <table className="w-full">
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "5%" }}>
          &nbsp;
        </td>
        <h2 class="text-2xl font-extrabold px-3 whitespace-nowrap tracking-tight relative top-[18.5px] text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
          {" "}
          Latest Properties
        </h2>
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "100%" }}>
          &nbsp;{" "}
        </td>

        <div class="border-2 border-[#9d7e2e] px-auto px-3 py-1 relative top-[18.5px] mx-3 text-[#9d7e2e] text-center rounded-lg whitespace-nowrap font-medium text-lg hover:border-black hover:text-black ">
          <NavLink to="/Listings"> <button class="uppercase">view all</button></NavLink>
        </div>
      </table>

      <div className="flex items-center">
        <MdChevronLeft
          className="lg:opacity-50 w-[7%] cursor-pointer hover:opacity-100"
          onClick={slideLeft}
          size={40}
        />
        <div
          id="slider"
          className="relative flex items-center overflow-x-scroll scroll-smooth"
        >
          <section>
            <div className="flex mt-10 mb-10 items-center justify-center ">
              {listing.map((house) => (
                <Link to={`/listings/${house.slug}`}>
                  {/* <div className="px-1 max-w-6xl z-50"> */}
                  <div className="w-[390px] lg:w-[420px] mx-[0.32rem]">
                    <div className="group relative h-96 cursor-pointer shadow-lg shadow-black/30 transition-all duration-300">
                      <img
                        className="h-full object-cover transition-all rounded-md shadow-lg"
                        src={house.main_photo}
                        alt=""
                      />

                      {/* <div className="lg:invisible absolute inset-0 bg-gradient-to-b from-green-500/20 to-black group-hover:visible"> */}
                      <div className="lg:visible absolute inset-0 bg-gradient-to-b from-green-500/20 to-black group-hover:visible">
                        <p className="font-bold text-white bg-[#9d7e2e] p-2 rounded-lg hover:bg-[#BB9E4B] transition-colors duration-300">
                          {house.home_type}
                        </p>


                        <div className="absolute inset-x-5 bottom-6">
                          <div className="">
                            <svg
                              width={4}
                              height={48}
                              viewBox="0 0 32 32"
                            ></svg>
                            <div>
                              <p className="font-medium text-xl tracking-wide uppercase text-gray-100">

                                {house.title}
                              </p>
                              <p className="font-medium uppercase text-gray-100">
                                {house.state}
                              </p>
                              {/* <p className="text-gray-300 flex font-light tracking-wider"> <span className="pt-1.5 pr-1"><img src="./Assets/Media/location_grey.png" alt="" /></span>
                                  PKR  {house.price}, </p> */}
                            </div>
                          </div>
                          {/* <div className="flex justify-start gap-3 text-gray-200 mt-2 brightness-[400%]">
                              <svg width={22} height={22} viewBox>
                              </svg><img src="./Assets/Media/12-layers.png" alt="" />
                            </div> */}
                          <div className="flex justify-between items-center mt-4">
                            <div>
                              <p className="flex items-center justify-center bg-[#9d7e2e] text-white h-10 w-36 rounded-lg text-sm font-medium tracking-wide hover:bg-[#BB9E4B] transition-colors duration-300">
                                Contact Seller
                              </p>

                            </div>
                            <div className="price border-2 border-[#9d7e2e] rounded-lg px-4 py-2 text-[#9d7e2e] font-bold hover:text-[#BB9E4B] underline underline-offset-4">
                              <p> PKR {house.price} </p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
              ))}
            </div>
          </section>
        </div>
        <MdChevronRight
          className="lg:opacity-50 w-[7%] cursor-pointer hover:opacity-100"
          onClick={slideRight}
          size={40}
        />
      </div>
    </>
  );
}

export default App;
