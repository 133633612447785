import React from 'react';
import PropTypes from 'prop-types';

const pagination = (props) => {
    const getNumbers = () => {
        let numbers = [];
        let itemsPerPage = props.itemsPerPage;
        let pageNumber = 1;

        for (let i = 0; i < props.count; i += itemsPerPage) {
            const page = pageNumber;
            let style = 'pagination__number';
            let content = null;

            if (props.active === page) {
                style = 'pagination__number pagination__number--active';
                content = (
                    <div key={i} className={style}>
                        {pageNumber}
                    </div>
                );
            }
            else {
                content = (
                    <div key={i} onClick={() => props.visitPage(page)} className={style}>
                        {pageNumber}
                    </div>
                );
            }

            numbers.push(
                content
            );
            pageNumber++;
        }

        return numbers;
    };
    
    return (
        <div className='border border-[#9d7e2e] rounded-lg p-1'>
            <div class="flex justify-center space-x-7 items-center">
            <div onClick={() => props.previous()} class="text-[#9d7e2e] bg-white cursor-pointer hover:underline px-[14px] text-sm py-1 ">
                Previous
            </div>
            <div onClick={() => props.next()} class="text-[#9d7e2e] bg-white cursor-pointer hover:underline px-[14px] text-sm py-1 ">
                Next
            </div>
            </div>
            <div className='flex justify-center items-center space-x-1 cursor-pointer text-[#9d7e2e]'>
            {getNumbers()}
            </div>
        </div>
    );
};

pagination.propTypes = {
    itemsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    visitPage: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
};

export default pagination;
