import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Cards from "../components/Cards";
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";
import { Link } from "react-router-dom";
import Features from "./../assets/Media/Features.png";
import phone from "./../assets/Media/phone_call_receiver.png";
import Envelope from "./../assets/Media/envelope.png";
import Smartphone from "./../assets/Media/Smartphone_mobile.png";
import Seller from "./../assets/Media/sellerinfofb.png";

const ListingDetail = (props) => {
  const [listing, setListing] = useState({});
  const [realtor, setRealtor] = useState({});
  const [price, setPrice] = useState(0);
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const slug = props.match.params.id;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/listings/${slug}`, config)
      .then((res) => {
        setListing(res.data);
        setPrice(numberWithCommas(res.data.price));
      })
      .catch((err) => { });
  }, [props.match.params.id]);
  useEffect(() => {
    const id = listing.realtor;

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/realtors/${id}`, config)
        .then((res) => {
          setRealtor(res.data);
        })
        .catch((err) => { });
    }
  }, [listing.realtor]);
  const displayInteriorImages = () => {
    let images = [];
    images.push(
      <div key={1} class="flex flex-row space-x-5">
        <div class="w-1/3">
          {listing.photo_1 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_1}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_2 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_2}
                alt=""
              />
            </div>
          ) : null}
        </div>

        <div class="w-1/3">
          {listing.photo_3 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_3}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={2} className="row">
        <div class="w-1/3">
          {listing.photo_4 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_4}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_5 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_5}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_6 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_6}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={3} className="row">
        <div class="w-1/3">
          {listing.photo_7 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_7}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_8 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_8}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_9 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_9}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={4} className="row">
        <div class="w-1/3">
          {listing.photo_10 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_10}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_12 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_11}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_12 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_12}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={5} className="row">
        <div class="w-1/3">
          {listing.photo_13 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_13}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_14 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_14}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_15 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_15}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={6} className="row">
        <div class="w-1/3">
          {listing.photo_16 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_16}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_17 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_17}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_18 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_18}
                alt=""
              />
            </div>
          ) : null}
        </div>
      </div>
    );
    images.push(
      <div key={7} className="row">
        <div class="w-1/3">
          {listing.photo_19 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_19}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3">
          {listing.photo_20 ? (
            <div class="bg-gray-300">
              <img
                class="h-full w-full object-cover"
                src={listing.photo_20}
                alt=""
              />
            </div>
          ) : null}
        </div>
        <div class="w-1/3"></div>
      </div>
    );
    return images;
  };



  const [currentImage, setCurrentImage] = useState(0);
  const images = [listing.main_photo, listing.photo_1, listing.photo_2, listing.photo_3];

  const dots = images.map((image, index) => (
    <span
      key={index}
      onClick={() => setCurrentImage(index)}
      className={`${currentImage === index ? 'bg-gray-800' : 'bg-gray-400'
        } h-2 w-2 mx-2 rounded-full cursor-pointer`}
    ></span>
  ));

  const handlePrev = () => {
    console.log('Clicked on previous button');
    setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
  };

  const handleNext = () => {
    console.log('Clicked on next button');
    setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
  };

  return (
    <div className="listingdetail overflow-x-hidden">
      <Helmet>
        <title>Realest Estate - Listing | {`${listing.title}`}</title>
        <meta name="description" content="Listing detail" />
      </Helmet>



      <div id="left-side" className="flex justify-evenly mt-14">
        <div className="xl:flex space-x-4">
          <div id="plumber-main" className="">
            <div className="px-8 py-4 bg-gray-100 text-center border-b">
              <p className="text-2xl font-bold text-gray-700 tracking-tight">
                <Link to="/" className="text-[#9d7e2e] hover:underline">
                  {listing.home_type}

                  / {listing.sale_type} / {listing.title} / {listing.city}
                </Link>
              </p>
            </div>
            <div className="bg-[#fafafa] lg:w-[700px] rounded-lg shadow">
              <div className="flex flex-col md:flex-row md:justify-between md:items-center py-6 px-3 md:px-4 bg-white rounded-lg shadow-md">

                <div className="md:w-1/3">
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800 text-[#9d7e2e] text-4xl font-medium tracking-tight mb-1.5">{listing.title}</h2>
                  <p className="text-[#9d7e2e] text-sm md:text-base">{listing.city}</p>
                </div>
                <div className="md:w-1/3 flex justify-center my-4 md:my-0">
                  <button className="uppercase bg-[#9d7e2e] hover:bg-blue-700 text-white text-base md:text-lg px-6 py-3 rounded-lg">{listing.sale_type}</button>
                </div>
                <div className="md:w-1/3 flex justify-end items-center">
                  <p className="text-[#9d7e2e] text-sm md:text-base mr-2">PKR:</p>
                  <p className="text-2xl md:text-3xl font-bold text-gray-800 tracking-wide">{listing.price}</p>
                </div>
              </div>



              <div className="relative" style={{ direction: 'rtl' }}>
                <img src={images[currentImage]} alt="Main" className="w-full h-auto" />

                <div className="absolute inset-y-0 left-0 flex items-center px-2 text-white z-10">
                  <button
                    className="bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
                    onClick={handlePrev}
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-white z-10">
                  <button
                    className="bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
                    onClick={handleNext}
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>

                <div className="w-full px-1 sm:px-0">
                  <div className="flex flex-wrap justify-center">
                    <img src={listing.photo_1} alt="Photo 1" className="w-1/6 mx-1 my-0.5" />
                    <img src={listing.photo_2} alt="Photo 2" className="w-1/6 mx-1 my-0.5" />
                    <img src={listing.photo_3} alt="Photo 3" className="w-1/6 mx-1 my-0.5" />
                  </div>
                </div>


                {/* Render dots below the images */}
                <div className="flex justify-center mt-2">{dots}</div>
              </div>

            </div>



            <div className="flex flex-col lg:flex-row lg:space-x-3 w-full lg:w-[700px] h-auto lg:h-[420px]">
              <div className="plumbing-featuresb shadow bg-[#fafafa] w-full lg:w-[350px] mb-8 lg:mb-0">


                <div className="md:w-1/1 bg-gray-100 rounded-lg p-6">
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">Features</h2>
                </div>

                <div className="border-b border-[#9d7e2e] mb-7" />
                <div className="text-area p-2 lg:flex lg:flex-wrap lg:justify-between">
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">Home Type:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.home_type}</span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">Bedrooms:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.bedrooms}</span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">Bathrooms:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.bathrooms}</span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">Unit:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.sqft}</span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">Address:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.address} </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">City:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.city}</span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-lg font-bold text-gray-600">State:</span>
                    <span className="text-lg text-[#9d7e2e]">{listing.state}</span>
                  </div>

                </div>

              </div>
              <div className="description bg-[#fafafa] shadow w-full lg:w-[350px]">


                <div className="md:w-1/1 bg-gray-100 rounded-lg p-6">
                  <h2 className="text-2xl md:text-3xl font-bold text-gray-800">About</h2>
                </div>
                <div className="border-b border-[#9d7e2e] mb-7" />
                <div className="text-area p-2 tracking-wide">
                  <p>{listing.description}.</p>
                </div>
              </div>
            </div>

          </div>
          <div id="right-side" className="w-full">
            <div>
              <form className="">
                <div className="bg-[#f5f5f5] p-10 MB-5 rounded-lg shadow space-y-5 w-[450px] h-[735px]">
                  <div className="text-black text-center text-2xl font-medium tracking-tight">
                    <h2>Agent Information</h2>
                  </div>
                  <div class="plumber-info text-white text-2xl tracking-tight font-medium">
                    <div class="flex justify-center space-x-2 items-center">
                      <img
                        src={realtor.photo}
                        alt=""
                        class=" rounded-full h-16 w-16"
                      />
                      <div class="text-[#9d7e2e] uppercase text-xl ">
                        {realtor.name}
                        <br />{" "}
                        <div>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="links-to-contact">
                    <div class="">
                      <div class="tracking-wide mt-7 mb-3 text-center text-lg font-medium">
                        <p>Contact the Agent</p>
                      </div>
                      <div class="imgs flex justify-center items-center space-x-8">
                        <div>
                          <img src={Envelope} alt="" />
                        </div>
                        <div>
                          <img src={Smartphone} alt="" />
                        </div>
                        <div>
                          <img src={Seller} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="number lg:bg-white text-[#9d7e2e] text-xl mx-auto rounded-full tracking-tight font-medium w-44 p-1 lg:border-[3px] border-[#9d7e2e] hover:underline">
                    <div className="flex space-x-2 justify-center items-center">
                      <img src={phone} alt="" className="w-7" />
                      <button>{realtor.phone} </button>
                    </div>
                  </div>
                  <div class="w-full relative group">
                    <input
                      type="text"
                      id="username"
                      required
                      class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                    ></input>
                    <label
                      for="username"
                      class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Name
                    </label>
                  </div>
                  <div class="w-full relative group">
                    <input
                      type="text"
                      id="username"
                      required
                      class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                    ></input>
                    <label
                      for="username"
                      class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Email
                    </label>
                  </div>
                  <div class="w-full relative group">
                    <input
                      type="text"
                      id="username"
                      required
                      class="w-full h-10 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                    ></input>
                    <label
                      for="username"
                      class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Phone
                    </label>
                  </div>
                  <div class="w-full relative group">
                    <textarea
                      type="text"
                      id="username"
                      required
                      class="w-full h-20 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                    ></textarea>
                    <label
                      for="username"
                      class="transform transition-all absolute top-0 left-0 h-full flex items-center pl-4 text-sm group-focus-within:text-xs peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0"
                    >
                      Message
                    </label>
                  </div>
                  <div className="flex justify-end">
                    <button>
                      <a
                        href="#_"
                        class="relative inline-flex items-center justify-start  py-3 pl-4 pr-12 overflow-hidden font-semibold text-[#60a1f6] transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group"
                      >
                        <span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-[#60a1f6] group-hover:h-full"></span>
                        <span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                          <svg
                            class="w-5 h-5 text-[#9d7e2e]"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M14 5l7 7m0 0l-7 7m7-7H3"
                            ></path>
                          </svg>
                        </span>
                        <span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                          <svg
                            class="w-5 h-5 text-[#9d7e2e]"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M14 5l7 7m0 0l-7 7m7-7H3"
                            ></path>
                          </svg>
                        </span>
                        <span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">
                          Submit
                        </span>
                      </a>
                    </button>
                    {/* <button>
                        <a
                          href="#_"
                          class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-[#60a1f6] border-2 border-gray-200 transition duration-300 ease-out rounded-full shadow-md group"
                        >
                          <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-[#60a1f6] group-hover:translate-x-0 ease">
                            <svg
                              class="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M14 5l7 7m0 0l-7 7m7-7H3"
                              ></path>
                            </svg>
                          </span>
                          <span class="absolute flex items-center justify-center w-full h-full text-[#9d7e2e] transition-all duration-300 transform group-hover:translate-x-full ease">
                            Contact
                          </span>
                          <span class="relative invisible">Contact</span>
                        </a>
                      </button> */}
                  </div>
                </div>
              </form>
            </div>

            <div class="for-ads bg-[#60a1f6] border-[2px] w-[450px] h-[420px] uppercase flex justify-center items-center border-blue-500 text-white text-xl tracking-tight mt-5 rounded-lg">
              <p>space available for ads</p>
            </div>
          </div>
        </div>
      </div>

      <Cards />
      <Add />
      <GetApp />
    </div>
  );
};

export default ListingDetail;
