import { useState, useEffect } from 'react';
import axios from "axios";

const ListingForm = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    sale_type: "",
    home_type: "",
    bedrooms: "",
    bathrooms: "",
    state: "",
    city: "",
    address: "",
    area: "",
    title: "",
    slug: "",
    price: "",
    unit_type: "",
    count: "",
    description: "",
    main_photo: "",
    photo_1: "",
    photo_2: "",
    photo_3: "",
    youtube_link: "",
    is_published: false,
  });

  const {
    title,
    slug,
    address,
    city,
    state,
    area,
    description,
    unit_type,
    bedrooms,
    bathrooms,
    price,
    sale_type,
    home_type,
    count,
    main_photo,
    photo_1,
    photo_2,
    photo_3,
    youtube_link,
    is_published,
  } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "title") {
      const currentDate = new Date().toISOString().substring(0, 10);
      const currentTime = new Date().getTime().toString().substring(6);
      const slug = `${value
        .toLowerCase()
        .replace(/\s/g, "-")}-${currentDate}-${currentTime}`;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
        slug: slug,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   };
  //   const data = new FormData();
  //   data.append("sale_type", sale_type);
  //   data.append("home_type", home_type);
  //   data.append("bedrooms", bedrooms);
  //   data.append("bathrooms", bathrooms);
  //   data.append("state", state);
  //   data.append("city", city);
  //   data.append("address", address);
  //   data.append("area", area);
  //   data.append("title", title);
  //   data.append("slug", slug);
  //   data.append("price", price);
  //   data.append("count", count);
  //   data.append("unit_type", unit_type);
  //   data.append("description", description);
  //   data.append("main_photo", main_photo.get("main_photo"));
  //   data.append("photo_1", photo_1.get("photo_1"));
  //   data.append("photo_2", photo_2.get("photo_2"));
  //   data.append("photo_3", photo_3.get("photo_3"));
  //   data.append("youtube_link", youtube_link);
  //   data.append("is_published", is_published ? 1 : 0);

  //   setLoading(true);
  //   axios
  //     .post("https://affanbaba.com/api/listings/manage", data, config)
  //     .then((res) => {
  //       setLoading(false);
  //       setSuccess("Listing created successfully");
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setError("Failed to create listing");
  //     });
  // };

  const handleSubmit = (event) => {
    event.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = new FormData();

    data.append("sale_type", sale_type);
    data.append("home_type", home_type);
    data.append("bedrooms", bedrooms);
    data.append("bathrooms", bathrooms);
    data.append("state", state);
    data.append("city", city);
    data.append("address", address);
    data.append("area", area);
    data.append("title", title);
    data.append("slug", slug);
    data.append("price", price);
    data.append("count", count);
    data.append("unit_type", unit_type);
    data.append("description", description);
    data.append("main_photo", main_photo.get("main_photo"));
    data.append("photo_1", photo_1.get("photo_1"));
    data.append("photo_2", photo_2.get("photo_2"));
    data.append("photo_3", photo_3.get("photo_3"));
    data.append("youtube_link", youtube_link);
    data.append("is_published", is_published ? 1 : 0);

    setLoading(true);
    axios
      .post("https://affanbaba.com/api/listings/manage", data, config)
      .then((res) => {
        setLoading(false);
        setSuccess("Submited  successfully will be live in 24 hours");
        setError("");
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message || "Failed to create listing");
        setSuccess("");
      });
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("main_photo", file);
    setFormData((prevState) => ({ ...prevState, main_photo: formData }));
  };
  const handleImageChangephoto_1 = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("photo_1", file);
    setFormData((prevState) => ({ ...prevState, photo_1: formData }));
  };
  const handleImageChangephoto_2 = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("photo_2", file);
    setFormData((prevState) => ({ ...prevState, photo_2: formData }));
  };
  const handleImageChangephoto_3 = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("photo_3", file);
    setFormData((prevState) => ({ ...prevState, photo_3: formData }));
  };


  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        window.location.href = '/';
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [success]);
  return (
    <div class="container px-6 py-12 h-full">
      <header class="text-center ml-6 font-bold uppercase text-lg">
        <h1
          class=" capitalize
        inline-block
        relative
        cursor-pointer
        transition-all
        duration-500
        before:content-['']
        before:absolute
        before:-bottom-2
        before:left-0
        before:w-0
        before:h-1.5
        before:rounded-full
        before:opacity-0
        before:transition-all
        before:duration-500
        before:bg-gradient-to-r
        before:from-blue-600
        before:via-slate-400
        before:to-zinc-500
        hover:before:w-full
        hover:before:opacity-100"
        >
          <span class="relative font-bold tracking-wider">Add property</span>
        </h1>
      </header>
      <div class="container px-6 py-12 h-full flex items-center justify-center">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="sale_type"
                >
                  Sale Type
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                  name="sale_type"
                  value={sale_type}
                  onChange={handleChange}
                >
                  <option value="FOR_RENT">For Rent</option>
                  <option value="FOR_SALE">For Sale</option>
                </select>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="home_type"
                >
                  Home Type
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                  name="home_type"
                  value={home_type}
                  onChange={handleChange}
                >
                  <option value="HOUSE">House</option>
                  <option value="FLAT">Flat</option>
                  <option value="PLOT">Plot</option>
                  <option value="COMMERCIAL">Commercial</option>
                  <option value="PROJECT">Projects</option>
                </select>
              </div>
            </div>
            {(home_type === "HOUSE" || home_type === "FLAT") && (
              <>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="bedrooms"
                  >
                    How Many bedrooms
                  </label>
                  <select
                    className="block w-full bg-gray-100 border border-gray-300 text-gray-700 py-3 px-4 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="bedrooms"
                    value={bedrooms}
                    onChange={handleChange}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </select>
                </div>
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                    htmlFor="bathrooms"
                  >
                    How Many bathrooms
                  </label>
                  <select
                    className="block w-full bg-gray-100 border border-gray-300 text-gray-700 py-3 px-4 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="bathrooms"
                    value={bathrooms}
                    onChange={handleChange}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </select>
                </div>
              </>
            )}
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="state"
                >
                  State
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                  name="state"
                  value={state}
                  onChange={handleChange}
                >
                  <option value="Sindh">Sindh</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Khyber Pakhtunkhwa">Khyber Pakhtunkhwa</option>
                  <option value="Balochistan">Balochistan</option>
                  <option value="Gilgit-Baltistan">Gilgit-Baltistan</option>
                  <option value="Azad Jammu and Kashmir">
                    {" "}
                    Azad Jammu and Kashmir{" "}
                  </option>
                </select>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="city"
                >
                  City
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                  name="city"
                  value={city}
                  onChange={handleChange}
                >
                  <option value="Karachi">Karachi </option>
                  <option value="Quetta">Quetta</option>
                  <option value="Islamabad">Islamabad</option>
                  <option value="Lahore">Lahore</option>
                  <option value="Rawalpindi">Rawalpindi</option>
                  <option value="Abbottabad">Abbottabad</option>
                  <option value="10594">Abdul Hakim</option>
                  <option value="12360">Ahmedpur East</option>
                  <option value="18624">Ali Masjid</option>
                  <option value="10242">Alipur</option>
                  <option value="9517">Arifwala</option>
                  <option value="1763">Astore</option>
                  <option value="1233">Attock</option>
                  <option value="1761">Awaran</option>
                  <option value="8857">Badin</option>
                  <option value="966">Bagh</option>
                  <option value="557">Bahawalnagar</option>
                  <option value="23">Bahawalpur</option>
                  <option value="14226">Balakot</option>
                  <option value="1735">Bannu</option>
                  <option value="13977">Barnala</option>
                  <option value="13808">Batkhela</option>
                  <option value="17727">Battagram</option>
                  <option value="1720">Bhakkar</option>
                  <option value="11142">Bhalwal</option>
                  <option value="1749">Bhimber</option>
                  <option value="11286">Buner</option>
                  <option value="1059">Burewala</option>
                  <option value="1747">Chaghi</option>
                  <option value="751">Chakwal</option>
                  <option value="11420">Charsadda</option>
                  <option value="8860">Chichawatni</option>
                  <option value="17728">Chilas</option>
                  <option value="1469">Chiniot</option>
                  <option value="17729">Chishtian</option>
                  <option value="10512">Chishtian Sharif</option>
                  <option value="1731">Chitral</option>
                  <option value="14284">Choa Saidan Shah</option>
                  <option value="1061">Chunian</option>
                  <option value="1727">Dadu</option>
                  <option value="11967">Daharki</option>
                  <option value="1509">Daska</option>
                  <option value="13599">Daur</option>
                  <option value="9178">Depalpur</option>
                  <option value="26">Dera Ghazi Khan</option>
                  <option value="8244">Dera Ismail Khan</option>
                  <option value="10645">Dijkot</option>
                  <option value="12718">Dina</option>
                  <option value="14195">Dobian</option>
                  <option value="8474">Duniya Pur</option>
                  <option value="1737">FATA</option>
                  <option value="16">Faisalabad</option>
                  <option value="1293">Fateh Jang</option>
                  <option value="10894">Gaarho</option>
                  <option value="19047">Gaddani</option>
                  <option value="11915">Gadoon</option>
                  <option value="8119">Galyat</option>
                  <option value="18016">Gambat</option>
                  <option value="13272">Ghakhar</option>
                  <option value="17730">Ghanche</option>
                  <option value="636">Gharo</option>
                  <option value="17768">Ghizar</option>
                  <option value="8810">Ghotki</option>
                  <option value="1753">Gilgit</option>
                  <option value="10281">Gojra</option>
                  <option value="8338">Gujar Khan</option>
                  <option value="327">Gujranwala</option>
                  <option value="20">Gujrat</option>
                  <option value="389">Gwadar</option>
                  <option value="1714">Hafizabad</option>
                  <option value="13607">Hala</option>
                  <option value="11739">Hangu</option>
                  <option value="11634">Harappa</option>
                  <option value="1048">Haripur</option>
                  <option value="1152">Haroonabad</option>
                  <option value="9687">Hasilpur</option>
                  <option value="399">Hassan Abdal</option>
                  <option value="10402">Haveli Lakha</option>
                  <option value="12823">Hazro</option>
                  <option value="9844">Hub Chowki</option>
                  <option value="13569">Hujra Shah Muqeem</option>
                  <option value="1546">Hunza</option>
                  <option value="30">Hyderabad</option>
                  <option value="3">Islamabad</option>
                  <option value="32">Jacobabad</option>
                  <option value="11126">Jahanian</option>
                  <option value="11026">Jalalpur Jattan</option>
                  <option value="10484">Jampur</option>
                  <option value="1178">Jamshoro</option>
                  <option value="17731">Jandola</option>
                  <option value="13706">Jatoi</option>
                  <option value="8511">Jauharabad</option>
                  <option value="1142">Jhang</option>
                  <option value="19">Jhelum</option>
                  <option value="9873">Kabirwala</option>
                  <option value="9202">Kaghan</option>
                  <option value="10279">Kahror Pakka</option>
                  <option value="1750">Kalat</option>
                  <option value="10416">Kamalia</option>
                  <option value="10346">Kamoki</option>
                  <option value="13611">Kandiaro</option>
                  <option value="2">Karachi</option>
                  <option value="9484">Karak</option>
                  <option value="544">Kasur</option>
                  <option value="8806">Khairpur</option>
                  <option value="1685">Khanewal</option>
                  <option value="10168">Khanpur</option>
                  <option value="17732">Khaplu</option>
                  <option value="1305">Kharian</option>
                  <option value="12390">Khipro</option>
                  <option value="8510">Khushab</option>
                  <option value="1757">Khuzdar</option>
                  <option value="1430">Kohat</option>
                  <option value="17733">Kohistan</option>
                  <option value="9749">Kot Addu</option>
                  <option value="968">Kotli</option>
                  <option value="8591">Kotri</option>
                  <option value="1">Lahore</option>
                  <option value="10205">Lakki Marwat</option>
                  <option value="9837">Lalamusa</option>
                  <option value="17734">Landi Kotal</option>
                  <option value="586">Larkana</option>
                  <option value="548">Lasbela</option>
                  <option value="1661">Layyah</option>
                  <option value="11406">Liaquatpur</option>
                  <option value="9872">Lodhran</option>
                  <option value="1742">Loralai</option>
                  <option value="10482">Lower Dir</option>
                  <option value="9422">Mailsi</option>
                  <option value="1767">Makran</option>
                  <option value="1384">Malakand</option>
                  <option value="1496">Mandi Bahauddin</option>
                  <option value="14350">Mangla</option>
                  <option value="771">Mansehra</option>
                  <option value="440">Mardan</option>
                  <option value="8606">Matiari</option>
                  <option value="14120">Matli</option>
                  <option value="9636">Mian Channu</option>
                  <option value="8310">Mianwali</option>
                  <option value="13476">Mingora</option>
                  <option value="17735">Miran Shah</option>
                  <option value="1349">Mirpur</option>
                  <option value="1558">Mirpur Khas</option>
                  <option value="10893">Mirpur Sakro</option>
                  <option value="13421">Mitha Tiwana</option>
                  <option value="13603">Moro</option>
                  <option value="15">Multan</option>
                  <option value="8116">Muridke</option>
                  <option value="36">Murree</option>
                  <option value="977">Muzaffarabad</option>
                  <option value="1722">Muzaffargarh</option>
                  <option value="1687">Nankana Sahib</option>
                  <option value="1258">Naran</option>
                  <option value="541">Narowal</option>
                  <option value="14962">Nasar Ullah Khan Town</option>
                  <option value="1752">Nasirabad</option>
                  <option value="8801">Naushahro Feroze</option>
                  <option value="1704">Nawabshah</option>
                  <option value="1741">Neelum</option>
                  <option value="1424">Nowshera</option>
                  <option value="470">Okara</option>
                  <option value="1716">Pakpattan</option>
                  <option value="17736">Pallandri</option>
                  <option value="17737">Parachinar</option>
                  <option value="17168">Pasrur</option>
                  <option value="8197">Pattoki</option>
                  <option value="17">Peshawar</option>
                  <option value="10678">Pind Dadan Khan</option>
                  <option value="975">Pindi Bhattian</option>
                  <option value="9508">Pir Mahal</option>
                  <option value="17711">Poonch</option>
                  <option value="13617">Qazi Ahmed</option>
                  <option value="18">Quetta</option>
                  <option value="40">Rahim Yar Khan</option>
                  <option value="17707">Raiwind</option>
                  <option value="14271">Rajana</option>
                  <option value="9645">Rajanpur</option>
                  <option value="17738">Rato Dero</option>
                  <option value="9027">Ratwal</option>
                  <option value="976">Rawalkot</option>
                  <option value="41">Rawalpindi</option>
                  <option value="8151">Renala Khurd</option>
                  <option value="1725">Rohri</option>
                  <option value="9538">Sadiqabad</option>
                  <option value="782">Sahiwal</option>
                  <option value="13438">Sakrand</option>
                  <option value="10632">Samundri</option>
                  <option value="8609">Sanghar</option>
                  <option value="8563">Sangla Hill</option>
                  <option value="1034">Sarai Alamgir</option>
                  <option value="778">Sargodha</option>
                  <option value="8607">Sehwan</option>
                  <option value="13211">Shabqadar</option>
                  <option value="9029">Shahdadpur</option>
                  <option value="8552">Shahkot</option>
                  <option value="13614">Shahpur Chakar</option>
                  <option value="12170">Shakargarh</option>
                  <option value="17739">Shangla</option>
                  <option value="13703">Shehr Sultan</option>
                  <option value="44">Sheikhupura</option>
                  <option value="13570">Sher Garh</option>
                  <option value="8808">Shikarpur</option>
                  <option value="10334">Shorkot</option>
                  <option value="480">Sialkot</option>
                  <option value="1744">Sibi</option>
                  <option value="1545">Skardu</option>
                  <option value="1745">Sudhnoti</option>
                  <option value="14329">Sujawal</option>
                  <option value="45">Sukkur</option>
                  <option value="3094">Swabi</option>
                  <option value="1506">Swat</option>
                  <option value="12137">Talagang</option>
                  <option value="9028">Tando Adam</option>
                  <option value="11315">Tando Allahyar</option>
                  <option value="11700">Tando Bago</option>
                  <option value="13166">Tando Muhammad Khan</option>
                  <option value="17740">Tank</option>
                  <option value="464">Taxila</option>
                  <option value="12439">Tharparkar</option>
                  <option value="1729">Thatta</option>
                  <option value="1658">Toba Tek Singh</option>
                  <option value="17741">Torkham</option>
                  <option value="12271">Turbat</option>
                  <option value="17742">Umarkot</option>
                  <option value="17743">Upper Dir</option>
                  <option value="1432">Vehari</option>
                  <option value="459">Wah</option>
                  <option value="17744">Wana</option>
                  <option value="1395">Wazirabad</option>
                  <option value="1765">Waziristan</option>
                  <option value="12504">Yazman</option>
                  <option value="1739">Zhob</option>
                </select>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="address"
                >
                  Address
                </label>
                <input
                  className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="text"
                  name="address"
                  value={address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="price"
                >
                  Area
                </label>
                <input
                  className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="text"
                  name="area"
                  value={area}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="title"
                >
                  Title
                </label>
                <input
                  className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="price"
                >
                  Price
                </label>
                <input
                  className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="number"
                  name="price"
                  value={price}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="unit_type"
                >
                  Unit Type
                </label>
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                  name="unit_type"
                  value={unit_type}
                  onChange={handleChange}
                >
                  <option value="SQFT">Sqft</option>
                  <option value="SQYD">Meters</option>
                  <option value="MARLA">Marla</option>
                  <option value="KANAL">Kanal</option>
                </select>
              </div>
            </div>
            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="count"
                >
                  Count
                </label>
                <input
                  className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="number"
                  name="count"
                  value={count}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="w-full px-3 mb-6 md:mb-0 mx-auto md:mt-3">
            <div>
              <div className="text-left">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="description"
                >
                  Description
                </label>
              </div>
              <input
                className="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                type="text"
                name="description"
                value={description}
                onChange={handleChange}
              />
            </div>
          </div>
          <div class="flex flex-col justify-center sm:flex-row sm:justify-between sm:mt-3">
            <div class="w-full sm:w-1/2 md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="main_photo"
                >
                  Main Photo
                </label>
                <input
                  class="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="file"
                  name="main_photo"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="photo_1"
                >
                  photo 1
                </label>
                <input
                  class="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="file"
                  name="photo_1"
                  onChange={handleImageChangephoto_1}
                />
              </div>
            </div>
            <div class="w-full sm:w-1/2 md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="photo_2"
                >
                  photo 2
                </label>
                <input
                  class="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="file"
                  name="photo_2"
                  onChange={handleImageChangephoto_2}
                />
              </div>
            </div>

            <div class="w-full sm:w-1/2 md:w-1/3 px-3 mb-6 md:mb-0">
              <div>
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="photo_3"
                >
                  photo 3
                </label>
                <input
                  class="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                  type="file"
                  name="photo_3"
                  onChange={handleImageChangephoto_3}
                />
              </div>
            </div>
          </div>

          <div className="w-full px-3 mb-6 md:mb-0 mx-auto md:mt-3">
            <div>
              <div className="text-left">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-state"
                  htmlFor="youtube_link"
                >
                  Youtube Link:
                </label>
              </div>
              <input
                className="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                type="text"
                name="youtube_link"
                value={youtube_link}
                onChange={handleChange}
              />
            </div>
          </div>


          {loading && <p>Loading...</p>}
          {success && <p>{success}</p>}
          {error && <p>{error}</p>}
          {/* {success && <p>{success}</p>}
          {error && <p>{error}</p>} */}
          <div class="w-full px-3 mb-6 md:mb-0 md:mx-auto md:mt-3">
            <div class="flex justify-center mt-8">
              <button
                type="submit"
                disabled={loading}
                class="relative inline-flex items-center justify-center px-12 py-5 overflow-hidden font-medium tracking-tighter text-white bg-[#9d7e2e] rounded-lg group"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ListingForm;
