import { useState, useEffect } from "react";
import axios from "axios";

function BlogPage() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("https://affanbaba.com/api/blog/");
                setPosts(response.data.results);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }
    return (

        <div className="flex flex-wrap justify-center">
            {posts.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {posts.map((post) => (
                        <div key={post.id} className="bg-white rounded-lg shadow-md p-5">
                            <img src={post.thumbnail} alt={post.title} className="w-full h-48 object-cover mb-3 rounded-lg" />
                            <h2 className="text-lg font-bold mb-2">{post.title}</h2>
                            <div className="content" dangerouslySetInnerHTML={{ __html: `${post.content.substring(0, 50)}...` }}>
                            </div>
                            <button className="text-blue-500 font-bold" onClick={() => window.location.href = `/post/${post.slug}`}>
                                Read More
                            </button>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No posts found.</p>
            )}
        </div>




    );
}

export default BlogPage;
