import React from 'react';
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";
const TermsAndConditions = () => {
  return (
    <>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-6 sm:max-w-5xl sm:mx-auto">
          <div className="absolute inset-0 bg-[#9d7e2e] from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-2 sm:rounded-3xl"></div>
          <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Terms &amp; Conditions</h2>
            </div>
            <h1 className="text-3xl font-bold text-center mb-8">User   Agreement</h1>
            <h2 className="text-lg font-semibold mb-3">1. Introduction</h2>
            <p className="mb-5">
              These Standard Terms and Conditions are (these “Terms” or these “Website &amp; Apps and other means of digital business’ Standard Terms and Conditions”) contained herein on this webpage, shall govern your use of this website/apps and any other digital mean of Business of Affanbaba.com.personal details. During your visit to the Site, you remain anonymous and at no time can we identify you unless you have an account on the Site and log on with your user’s name and password.
            </p>
            <h2 className="text-lg font-semibold mb-3">2. Intellectual Property Rights</h2>
            <p className="mb-5">
              Other than content you own, which you may have....
            </p>
            <h2 className="text-lg font-semibold mb-3">3. Restrictions</h2>
            <p className="mb-5">
              You are expressly and emphatically restricted from all of the following:
            </p>
            <ol className="list-decimal ml-8 mb-5">
              <li>Publishing any Website material in any other media of any kind;</li>
              <li>Selling, sublicensing and/or otherwise commercializing any Website material;</li>
              <li>Publicly performing and/or showing any Website material;</li>
              <li>Using this Website in any way that is, or may be, damaging to this Website;</li>
              <li>Using this Website in any way that impacts user access to this Website;</li>
              <li>Using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;</li>
              <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;</li>
              <li>Using this Website to engage in any advertising or marketing;</li>
            </ol>

            <h2 className="text-lg font-semibold mb-3">4. Data Collection</h2>
            <p className="mb-5">
              We may collect various pieces of information if you seek to place an order for a product or service with us on the Site. This includes your name, address, phone number, email address, and payment information. We may also collect additional information such as your IP address, browser type, and other anonymous usage data to help us improve our website and services.
            </p>
            <p className="mb-5">
              We will use the information you provide to enable us to process your orders and to provide you with the services and information offered through our website and which you request. If you have opted-in to receive marketing communications from us, we may also use your information to send you promotional emails or newsletters. If you no longer wish to receive marketing communications from us, you can opt out at any time.
            </p>
            <p className="mb-5">
              We may pass your name and address on to a third party in order to make delivery of the product to you (for example to our courier or supplier). Payment details are processed securely by our third-party payment processors and we do not store your credit/debit card information.
            </p>
            <p className="mb-5">
              By using this website, you agree to these terms and conditions and our privacy policy. If you do not agree, please do not use our website. We reserve the right to make changes to these terms and conditions at any time without notice to you, so please check back periodically for updates.
            </p>
            <h2 className="text-lg font-semibold mb-3">5. Other Personal Information</h2>
            <p className="mb-5">
              We may use your personal information for opinion and market research. Your details are anonymous and will only be used for statistical purposes. You can choose to opt out of this at any time. Any answers to surveys or opinion polls we may ask you to complete will not be forwarded on to third parties. Disclosing your email address is only necessary if you would like to take part in competitions. We save the answers to our surveys separately from your email address.
            </p>
            <p className="mb-5">
              We may also send you other information about us, the Site, our other websites, our products, sales promotions, our newsletters, anything relating to other companies in our group or our business partners. If you would prefer not to receive any of this additional information as detailed in this paragraph (or any part of it) please click the 'unsubscribe' link in any email that we send to you. Within 21 working days (days which are neither (i) a Sunday, nor (ii) a public holiday anywhere in Pakistan) of receipt of your instruction we will cease to send you information as requested. If your instruction is unclear, we will contact you for clarification.
            </p>
            <p className="mb-5">
              We may further anonymize data about users of the Site generally and use it for various purposes, including ascertaining the general location of the users and usage of certain aspects of the Site or a link contained in an email to those registered to receive them, and supplying that anonymized data to third parties such as publishers. However, that anonymized data will not be capable of identifying you personally.
            </p>
            <h2 className="text-lg font-semibold mb-3">6. Third Parties and Links</h2>
            <p className="mb-5">
              We may pass your details to other companies in our group. We may also pass your details to our agents and subcontractors, suppliers, servicemen, or agents to help us with any of our uses of your data set out in our Privacy Policy. For example, we may use third parties to assist us with delivering products to you, to help us to collect payments from you, to analyses data and to provide us with marketing or customer service assistance. We may exchange information with third parties for the purposes of fraud protection and credit risk reduction. We may transfer our databases containing your personal information if we sell our business or part of it. Other than as set out in this Privacy Policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law. The Site may contain advertising of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or content of those third parties or other sites, nor for any third party to whom we transfer your data in accordance with our Privacy Policy.
            </p>
            <h2 className="text-lg font-semibold mb-3">7. Your Content</h2>
            <p className="mb-5">
              In these Website Standard Terms and Conditions, “Your Content” shall mean any audio, video, text, images or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant Affanbaba.com a non-exclusive, worldwide, irrevocable, royalty- free, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media. Your Content must be your own and must not be infringing on any third party’s rights. Affanbaba.com reserves the right to remove any of Your Content from this Website at any time, and for any reason, without notice.
            </p>
            <h2 className="text-lg font-semibold mb-3">8.	Security</h2>
            <p className="mb-5">
              We have in place appropriate technical and security measures to prevent unauthorized or unlawful access to or accidental loss of or destruction or damage to your information. When we collect data through the Site, we collect your personal details on a secure server. We use firewalls on our servers. Our security procedures mean that we may occasionally request proof of identity before we disclose personal information to you. You are responsible for protecting against unauthorized access to your password and to your computer.
            </p>
            <h2 className="text-lg font-semibold mb-3">9. Payments, Refunds & Returns – Goods & Services</h2>
            <p className="mb-5">
              Affanbaba.com provides buyers and sellers with various payment options for exchanging goods and services on its platform. As a user, you are responsible for using these payment facilities appropriately and in compliance with the terms and conditions of payment gateway providers like Visa, Master, Jazz Cash, Easypaisa, bank transfer/deposit, or cash collection facility. Additionally, you must agree to the cancellation, return, exchange, and refund policy for each product, service, or subscription purchase.
            </p>
            <p className="mb-5">
              Affanbaba.com sellers or service providers may or may not offer full or partial money-back guarantees on some or all purchases, depending on the product or service. Please read the terms and conditions provided in each product or service offering before making a purchase. Each offering is regulated under a single product or service policy, and you must follow the correct procedure to determine the condition that applies to your situation.
            </p>
            <p className="mb-5">
              If you are unsatisfied with the property, project, product, or service that you purchased from a seller, service provider, agent, or builder registered on Affanbaba.com, please inform us. The Returns & Refunds Policy allows you a certain number of days to return or exchange an item with a valid receipt, depending on the product or service listing offering. After the refund time has elapsed, sellers cannot offer you a refund or exchange.
            </p>
            <p className="mb-5">
              We encourage our customers to try the product or service within the first refund period after their purchase to ensure it meets their needs. To be eligible for a refund or exchange, all physical products must be returned in their original, unmarked packaging, including any accessories, labels, free gifts, bonus items, manuals, and documentation that shipped with the product. If the article is returned unopened in the original box, the seller shall exchange it or offer you a refund based on your original method of payment, excluding any shipping charges (other than the original shipping costs invoiced).
            </p>
            <h2 className="text-lg font-semibold mb-3">10. Refunds & Returns – Advertising and Marketing</h2>
            <p className="mb-5">
              Affanbaba.com provides multiple payment options for the exchange of goods and services against purchases and subscriptions, and you must agree to the terms and conditions of payment gateway providers like Visa, Master, Jazz Cash, Easypaisa, bank transfer/deposit, or cash collection facility. As a user, you must use appropriate means of care and compliance while using such facilities.
            </p>
            <p className="mb-5">
              You must also agree to the terms and conditions on cancellation, return, exchange, and refund policy for each product, service, or subscription purchased.
            </p>
            <p className="mb-5">
              Affanbaba.com may offer full or partial money-back guarantees on some or all purchases made by customers who place their advertisement banners on Affanbaba.com to promote their brand or generate more leads. This is subject to certain conditions due to calendar and slots availability of each advertising banner on Affanbaba.com.
            </p>
            <p className="mb-5">
              In order to be eligible for a full or partial refund, the customer must book the advertising banner calendar and slots for future display and must cancel at least three days in advance to be eligible for cancellation and a 100% refund, provided that the customer had furnished the design content during this grace period.
            </p>
            <p className="mb-5">
              Affanbaba.com may decline the request for a refund in full or partial if the booking made was for a future campaign and was canceled less than three days in advance of the campaign/advertising banner going live on Affanbaba.com, or if a refund is requested after the design or strategy campaign has been made, booked, and made live on digital platforms. The customer is fully liable and responsible for the loss of money, time/slots, and resources applied by Affanbaba.com and the loss of opportunities that could have been offered to other customers by Affanbaba.com, and therefore, a full or partial refund may not be offered at the sole discretion held with Affanbaba.com.
            </p>
            <h2 className="text-lg font-semibold mb-3">11. Your Rights</h2>
            <p className="mb-5">
              If you are concerned about your data, you have the right to request access to the personal data which we may hold or process about you. You have the right to require us to correct any inaccuracies in your data free of charge. At any stage you also have the right to ask us to stop using your personal data for direct marketing purposes.
            </p>
            <h2 className="text-lg font-semibold mb-3">12. No Warranties</h2>
            <p className="mb-5">
              This Website is provided “as is” with all faults, and Affanbaba.com makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.
            </p>
            <h2 className="text-lg font-semibold mb-3">13. Limitation of Liability</h2>
            <p className="mb-5">
              In no event shall Affanbaba.com or any of its group companies, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, Apps and any other means whether such liability is under contract, tort or otherwise, and Affanbaba.com, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website/Apps/ an all other means of Digital Business.
            </p>
            <h2 className="text-lg font-semibold mb-3">14. Indemnification</h2>
            <p className="mb-5">
              You hereby indemnify to the fullest extent Affanbaba.com and its group companies or its subsidiaries from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.
            </p>
            <h2 className="text-lg font-semibold mb-3">15. Severability</h2>
            <p className="mb-5">
              If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
            </p>
            <h2 className="text-lg font-semibold mb-3">16. Variation of Terms</h2>
            <p className="mb-5">
              Affanbaba.com and its group companies are permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review such Terms on a regular basis to ensure you understand all terms and conditions governing use of this Website.
            </p>
            <h2 className="text-lg font-semibold mb-3">17. Assignment</h2>
            <p className="mb-5">
              Affanbaba.com shall be permitted to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification or consent required. However, you shall not be permitted to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
            </p>
            <h2 className="text-lg font-semibold mb-3">18. Entire Agreement</h2>
            <p className="mb-5">
              These Terms, including any legal notices and disclaimers contained on this Website, constitute the entire agreement between Affanbaba.com and you in relation to your use of this Website, and supersede all prior agreements and understandings with respect to the same.
            </p>
            <h2 className="text-lg font-semibold mb-3">19. Governing Law & Jurisdiction</h2>
            <p className="mb-5">
              These Terms will be governed by and construed in accordance with the laws of Pakistan, and you submit to the non-exclusive jurisdiction of Pakistan and courts of Pakistan for the resolution of any disputes.
            </p>
            <p className="mb-5">
              This Terms & Conditions under user agreement has been updated on 14th August 2022.
            </p>
          </div>
        </div>

      </div>
      <Add />
      <GetApp />
    </>
  );
}

export default TermsAndConditions;
