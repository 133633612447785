import React from "react";
import { NavLink } from "react-router-dom";

import googleplay from "./../assets/Media/footer google play.png";
import Iphone from "./../assets/Media/footer app store.png";
import phone from "./../assets/Media/footer mobile_phones (1).png";
import fb from "./../assets/Media/facebook_logo.png";
import tr from "./../assets/Media/Twitter_logo_white.png";
import inta from "./../assets/Media/Instagram_Logo_white.png";
import Logo from "./../assets/Media/2ndnav.png"

export default function GetApp() {
  return (
    <>
      <div class="hidden lg:block">
        <section>
          <div class="flex mt-28 mb-28 ">
            <div class="space-for-ads-3 mr-7 ml-7 mt-10 bg-[#60a1f6] w-[128px] h-[120px] text-white uppercase flex justify-center items-center border-[2px] border-blue-500 tracking-wider text-lg">
              <div class="text-center">
                <p>200 x 200</p>
                <p>space</p>
                <p>for ads</p>
              </div>
            </div>
            <div class="bg-[#eeeeee] w-full h-[200px] flex items-center justify-evenly p-5 ">
              <div class=" ">
                <img
                  class="h-80 relative right-[50px]"
                  src={phone}
                  alt=""
                />
              </div>
              <div class="tracking-wider pr-20">
                <h2 class="text-[#9d7e2e] font-medium text-2xl tracking-widest">
                  GET AFFANBABA APP
                </h2>
                <p>Experience buying and renting property</p>
                <p>on the move using AffanBaba app</p>
              </div>
              <div class="">
                <div class="w-36 mb-3">
                  <img src={googleplay} alt="" />
                </div>
                <div class="w-36">
                  <img src={Iphone} alt="" />
                </div>
              </div>
            </div>

            <div class="space-for-ads-3 mr-7 ml-7 mt-10 bg-[#60a1f6] w-[128px] h-[120px] text-white uppercase flex justify-center items-center content-center border-[2px] border-blue-500 tracking-wider text-lg">
              <div class="text-center">
                <p>200 x 200</p>
                <p>space</p>
                <p>for ads</p>
              </div>
            </div>
          </div>

          <main>
            <div
              id="main-footer"
              class="bg-[#343434] text-white flex justify-evenly py-10"
            >

              <div class="connect">

                <div class="">
                  <NavLink to="/"> <img src={Logo} alt="" /></NavLink>
                </div>
              </div>
              <div class="company-1">
                <h4 class="text-xl font-medium mb-7 tracking-wider">Company</h4>
                <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                  <ul>
                    <NavLink to="/about"> <li className="hover:brightness-[400%] cursor-pointer"> About Us</li> </NavLink>
                    {/* <NavLink to="/"><li className="hover:brightness-[400%] cursor-pointer">Jobs</li> </NavLink> */}
                    <NavLink to="/faqs"><li className="hover:brightness-[400%] cursor-pointer">FAQs</li> </NavLink>
                    <NavLink to="/PrivacyPolicy"><li className="hover:brightness-[400%] cursor-pointer">Privacy Policy</li> </NavLink>
                    <NavLink to="/terms"> <li className="hover:brightness-[400%] cursor-pointer">Terms & Conditions</li> </NavLink>

                  </ul>
                </div>
              </div>


              <div class="company-2">
                <h4 class="text-xl font-medium mb-7 tracking-wider">

                  <NavLink to="/AddRealtors"> Pages </NavLink>
                </h4>
                <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                  <ul>
                    <NavLink to="/Listings"> <li className="hover:brightness-[400%] cursor-pointer">HOME</li></NavLink>
                    <NavLink to="/Plot"> <li className="hover:brightness-[400%] cursor-pointer">PLOT</li> </NavLink>
                    <NavLink to="/flat"> <li className="hover:brightness-[400%] cursor-pointer">FLAT</li></NavLink>
                    <NavLink to="/Commercial"><li className="hover:brightness-[400%] cursor-pointer">COMMERCIAL</li></NavLink>
                    <NavLink to="/Projects"> <li className="hover:brightness-[400%] cursor-pointer">Projects </li></NavLink>
                    <NavLink to="/Agents"> <li className="hover:brightness-[400%] cursor-pointer">AGENTS </li></NavLink>
                    <NavLink to="/Blog">  <li className="hover:brightness-[400%] cursor-pointer">BLOG</li></NavLink>
                  </ul>
                </div>
              </div>

              <div class="right-side-footer">
                <h4 class="text-xl font-medium mb-4 tracking-wider">
                  CONTECT US
                </h4>
                <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                  <ul class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                    <li>info@affanbaba.com</li>
                    <li>query@affanbaba.com</li>
                    <li>+92 300 203 7262</li>
                    <li>+92 213 278 8833</li>
                    <li>Karachi, PK</li>
                  </ul>
                </div>
                <div>
                  <h4 class="text-xl font-medium mb-4 mt-10 tracking-wider">
                    Connect With Us
                  </h4>
                </div>
                <div class="flex space-x-7">
                  <img src={fb} alt="" />
                  <img src={tr} alt="" />
                  <img src={inta} alt="" />
                </div>
              </div>
            </div>
          </main>
          <div class="last-footer bg-[#9d7e2e] p-5 space-y-5">
            <div>
              <h4 class="text-gray-300 text-sm font-medium mb-4 text-center whitespace-nowrap tracking-wider">
                Copyright &copy; 2023 <b>Affan Baba</b>. All Rights Reserved.
              </h4>
            </div>
            {/* <div class="flex justify-center space-x-7">
              <img src={fb} alt="" />
              <img src={tr} alt="" />
              <img src={inta} alt="" />
            </div> */}
          </div>

        </section>
      </div>

      <div class="visible lg:hidden">
        <div class="bg-[#eeeeee] mb-10 p-5 text-center space-y-5">
          <div class="tracking-wider font-medium text-lg">
            <h2 class="text-[#9d7e2e] font-medium text-2xl tracking-widest">
              GET AFFANBABA APP
            </h2>
            <p>Experience buying and renting property</p>
            <p>on the move using AffanBaba app</p>
          </div>
          <div class="flex justify-center space-x-4">
            <div class="w-28 mt-2">
              <img src={googleplay} alt="" />
            </div>
            <div class="w-28">
              <img src={Iphone} alt="" />
            </div>
          </div>
        </div>

        <div>
          <img
            src={phone}
            alt=""
            class="w-[250px] h-[210px] m-auto mb-10 sm:w-[300px] sm:h-[260px] lg:w-[350px] lg:h-[310px]"
          />
        </div>

        <div id="main-footer" class="bg-[#343434] text-white p-10 space-y-7">
          <div>
            <img src="./Assets/Media/1stnav.png" alt="" class="m-auto" />
          </div>
          <div class="flex justify-between">
            <div class="company-1">
              <h4 class="text-xl font-medium mb-7 tracking-wider">Company</h4>
              <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                <ul>
                  <li>About Us</li>
                  <li>Jobs</li>
                  <li>Help & Support</li>
                  <li>Privacy Policy</li>
                  <li>Terms & Conditions</li>
                  <li>PROPERTIES</li>
                </ul>
              </div>
            </div>

            <div class="connect">
              <h4 class="text-xl font-medium mb-7 tracking-wider">
                Pages
              </h4>
              <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                <ul>
                  <NavLink to="/ELECTRIC"> <li>HOME</li></NavLink>
                  <NavLink to="/PLUMBING"> <li>PLOT</li> </NavLink>
                  <NavLink to="/CARPENTER"> <li>FLAT</li></NavLink>
                  <NavLink to="/PAINT"><li>COMMERCIAL</li></NavLink>
                  <NavLink to="/GENERATOR"> <li>RENT </li></NavLink>
                  <NavLink to="/GENERATOR"> <li>AGENTS </li></NavLink>
                  <NavLink to="/ServicesHome">  <li>BLOG</li></NavLink>
                </ul>
              </div>
            </div>
          </div>

          <div class="m-auto text-center">
            <h4 class="text-xl font-medium mb-4 tracking-wider ">
              Corporate Office
            </h4>
            <div class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
              <ul class="text-sm font-extralight leading-6 [word-spacing:0.20rem] tracking-wide">
                <li>info@affanbaba.com</li>
                <li>query@affanbaba.com</li>
                <li>+92 306 8828807</li>
                <li>Karachi, PK</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="last-footer bg-[#9d7e2e] p-5 space-y-5">
          <div>
            <h4 class="text-gray-300 text-sm font-medium mb-4 text-center whitespace-nowrap tracking-wider">
              Copyright &copy; 2023 <b>Affan Baba</b>. All Rights Reserved.
            </h4>
          </div>
          {/* <div class="flex justify-center space-x-7">
            <img src={fb} alt="" />
            <img src={tr} alt="" />
            <img src={inta} alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}
