import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

function Agents() {
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/realtors/topseller`
        );
        setAgents(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  if (!agents) {
    return <div>Loading...</div>;
  }

  const slideLeft1 = () => {
    var slider = document.getElementById("slider1");
    slider.scrollLeft = slider.scrollLeft - 315;
  };

  const slideRight1 = () => {
    var slider = document.getElementById("slider1");
    slider.scrollLeft = slider.scrollLeft + 315;
  };
  return (
    <>
      <table className="w-full mt-16">
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "5%" }}>
          &nbsp;
        </td>
        <h2 class="text-2xl font-extrabold px-3 whitespace-nowrap tracking-tight relative top-[18.5px] text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
          {" "}
          Top Agents
        </h2>
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "100%" }}>
          &nbsp;{" "}
        </td>

        <div class="border-2 border-[#9d7e2e] px-auto px-3 py-1 relative top-[18.5px] mx-3 text-[#9d7e2e] text-center rounded-lg whitespace-nowrap font-medium text-lg hover:border-black hover:text-black ">
          <NavLink to="/Listings">
            {" "}
            <button class="uppercase">view all</button>
          </NavLink>
        </div>
      </table>

      <div className="flex items-center ">
        <MdChevronLeft
          className="lg:opacity-50 w-[20%] cursor-pointer hover:opacity-100"
          onClick={slideLeft1}
          size={40}
        />
        <div
          id="slider1"
          className="relative flex items-center overflow-x-auto sm:overflow-x-scroll"
        >
          <section>
            <div className="flex mt-10 mb-10 items-center justify-center ">
              {agents.map((agent) => (
                <Link to={`/realtors/${agent.id}`}>
                  <div className="w-[350.80px] mx-1">
                    <div
                      className="h-40 sm:h-48 md:h-60 lg:h-72 rounded-xl object-cover"
                      style={{ backgroundImage: `url(${agent.photo})`, backgroundSize: 'cover' }}
                    >
                    </div>

                    <div className="bg-[#9d7e2e] px-3 relative bottom-[10px] text-white py-3">
                      <div className="text-xl">{agent.name}</div>
                      <div className="text-sm tracking-tight">Karachi</div>
                      <div className="flex justify-between">
                        <div className="relative bottom-[170px] left-[75px] bg-[#9d7e2e] font-bold bg-opacity-[0.50] uppercase h-7  flex items-center text-sm tracking-wide px-2 hover:bg-[#292e4a] cursor-pointer">
                          Contact Agent
                        </div>
                        <div className="text-white font-bold text-lg italic uppercase py-1 px-2 border border-black rounded-full">
                          Top Seller
                        </div>
                      </div>
                    </div>
                  </div>

                </Link>
              ))}
            </div>

          </section>
        </div>
        <MdChevronRight
          className="lg:opacity-50 w-[20%] cursor-pointer hover:opacity-100"
          onClick={slideRight1}
          size={40}
        />
      </div>
    </>
  );
}

export default Agents;
