import React, { useState } from "react";
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";
const Faq = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
      <button type="button" className="flex items-center justify-between w-full px-4 py-5 sm:p-6" onClick={toggleOpen}>
        <span className="flex text-lg font-semibold text-black">{question}</span>

        {isOpen ? (
          <svg className="w-6 h-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
          </svg>
        ) : (
          <svg className="w-6 h-6 text-gray-400 rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
          </svg>
        )}
      </button>

      {isOpen && (
        <div className="px-2 py-3 sm:px-6 sm:py-6 lg:px-8 lg:py-8 bg-white rounded-lg shadow-md border border-gray-200">
          <p className="text-1xl font-bold text-gray-800">{answer}</p>
        </div>


      )}
    </div>
  );
};
const FaqList = () => {
  const faqs = [
    {
      question: "What are the benefits of listing my property on Affanbaba.com?",
      answer:
        "Affanbaba.com is home to thousands of people looking to buy and rent properties, your property has a better chance of getting discovered by interested buyers and tenants from here than anywhere else. Listing your property on Affanbaba.com is quite simple and does not take long.",
    },
    {
      question: "How do I search for a Property on Affanbaba.com?",
      answer:
        "Searching for a property on Affanbaba.com is simple, just visit our homepage and in the filterbar set a location for your desired property. You can also narrow down your search by filtering your search by city, property type, area unit and prices.",
    },
    {
      question: "How long can I market my property with you?",
      answer:
        "You can market your property with Affanbaba.com for as long as you wish.",
    },
    {
      question: "How can I share my property with my friends on Facebook?",
      answer:
        "You can share your property with your friends with the link of the property page on Affanbaba.com.",
    },
    {
      question: "What should I disclose to potential buyers?",
      answer:
        "When you list a property on our site, make sure you provide all important details like the property address, number of rooms, price, area, amenities, and good photos.",
    },
    {
      question: "How do I contact an agency from Affanbaba.com?",
      answer:
        "You can contact an agency Affanbaba.com by filling in the contact form with your name, email, and message or on the number they have provided.",
    },
    {
      question: "What do I do with my Affanbaba.com account?",
      answer:
        "With your Affanbaba.com account you can:\n• Stay connected with latest property listings\n• Find a property for sale or rent\n• List a property for sale or rent\n• Save properties and projects that you like to view later.",
    },
    {
      question: "What do I do if I forget my password?",
      answer:
        "If you forget your password, during Sign in, click/tap on forget password and enter your email address. We will email you with a password reset link through which you can set a new password for your account.",
    },
    {
      question: "How do I stay connected to Affanbaba.com?",
      answer:
        "You can sign up for our newsletter via email or download the Affanbaba.com app to stay connected with the latest property listings and happenings in the real estate world.",
    },
    {
      question: "Will my property be listed as soon as I submit it?",
      answer:
        "Yes, your property will be live on our site within 24 Hours as you submit it.",
    },
    {
      question: "Are there any charges to use Affanbaba.com?",
      answer:
        "Affanbaba.com is completely free to use.",
    },
    {
      question: "Where will interested buyers or tenants contact me?",
      answer:
        "Interested buyers or tenants will contact you on the contact details you provide on our site.",
    },
    {
      question: "How do I list my property on Affanbaba.com?",
      answer: "To list a property on Affanbaba.com:\n1. Sign in to your Affanbaba.com account.\n2. Click or Tap on the List Property button from the homepage\n3. Enter property details including features, pictures, amenities, and other details.\n4. Submit it once you are done.\n5. Your listing will be live as soon as our team is done reviewing it.",
    },
    {
      question: "Are there any limits to the number of Properties I can list on Affanbaba.com?",
      answer: "ProSeller can list unlimited properties on Affanbaba.com without any problems.",
    },
    {
      question: "Do I contact more than one agent for buying and selling decisions?",
      answer: "Yes, you have the freedom to contact as many agents as you like!",
    },
    {
      question: "Which property location is best?",
      answer: "The best property location for you depends on a variety of factors including amenities, utilities, your budget, etc. Stay connected to our blogs to get more insight on how to make the right buying and renting decisions",
    },
    {
      question: "How do I inquire about a project on Affanbaba.com?",
      answer: "If you wish to know more details about a project on our site, go to the project and enter your email address with your name and query or call on the provided number. You will soon be contacted by someone on their team",
    }
  ];

  return (
    <>
      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">



          <div className="relative py-6 sm:max-w-6xl sm:mx-auto">
            <div className="absolute inset-0 bg-[#9d7e2e] from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-1 sm:rounded-3xl"></div>
            <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
              <div className="max-w-2xl mx-auto text-center">
                <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Frequently  Asked Questions </h2>
              </div>
              <div className="max-w-7xl mx-auto mt-8 space-y-4 md:mt-16">
                {faqs.map((faq, index) => (
                  <Faq key={index} question={faq.question} answer={faq.answer} />
                ))}
              </div>


            </div>
          </div>

        </div>

      </section>

      <Add />
      <GetApp /></>
  );
};

export default FaqList;
