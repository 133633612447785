import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Card from "../components/Card";
import Pagination from "../components/Pagination";
import GetApp from "../components/GetApp";
import Add from "../components/AddOne";
import SidebarSearch from "./../components/SidebarSearch";

const Listings = () => {
    const [listings, setListings] = useState([]);
    const [count, setCount] = useState(0);
    const [previous, setPrevious] = useState("");
    const [next, setNext] = useState("");
    const [active, setActive] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_API_URL}/api/listings/?page=1`
                );

                setListings(res.data.results);
                setCount(res.data.count);
                setPrevious(res.data.previous);
                setNext(res.data.next);
            } catch (err) { }
        };

        fetchData();
    }, []);

    const displayListings = () => {
        let display = [];
        let result = [];

        // listings.map((listing) => {
        //   return display.push(
        //     <Card
        //       title={listing.title}
        //       address={listing.address}
        //       city={listing.city}
        //       state={listing.state}
        //       price={listing.price}
        //       sale_type={listing.sale_type}
        //       home_type={listing.home_type}
        //       bedrooms={listing.bedrooms}
        //       bathrooms={listing.bathrooms}
        //       sqft={listing.sqft}
        //       main_photo={listing.main_photo}
        //       slug={listing.slug}
        //     />
        //   );
        // });

        listings.map((listing) => {
            if (listing.home_type === "PROJECTS") {
                display.push(
                    <Card
                        title={listing.title}
                        address={listing.address}
                        city={listing.city}
                        state={listing.state}
                        price={listing.price}
                        sale_type={listing.sale_type}
                        home_type={listing.home_type}
                        unit_type={listing.unit_type}
                        bedrooms={listing.bedrooms}
                        bathrooms={listing.bathrooms}
                        sqft={listing.sqft}
                        main_photo={listing.main_photo}
                        slug={listing.slug}
                    />
                );
            } else {
                // Display other types of homes here
            }
        });
        for (let i = 0; i < listings.length; i += 3) {
            result.push(
                <div key={i} class="flex flex-wrap -mx-4">
                    <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
                        {display[i]}
                    </div>
                    <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
                        {display[i + 1] ? display[i + 1] : null}
                    </div>
                    <div class="w-full sm:w-1/2 lg:w-1/3 p-4">
                        {display[i + 2] ? display[i + 2] : null}
                    </div>
                </div>

            );
        }

        return result;
    };

    const visitPage = (page) => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/listings/?page=${page}`)
            .then((res) => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                setActive(page);
            })
            .catch((err) => { });
    };

    const previous_number = () => {
        axios
            .get(previous)
            .then((res) => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (previous) setActive(active - 1);
            })
            .catch((err) => { });
    };

    const next_number = () => {
        axios
            .get(next)
            .then((res) => {
                setListings(res.data.results);
                setPrevious(res.data.previous);
                setNext(res.data.next);
                if (next) setActive(active + 1);
            })
            .catch((err) => { });
    };

    return (<>


        <div class="flex flex-col md:flex-row lg:flex-row lg:w-full lg:justify-between lg:items-center">
            <div class="lg:w-1/4 lg:p-8">
                <SidebarSearch />
            </div>
            <section class="w-full mt-8 lg:flex lg:items-start">
                <div class="mt-6 lg:mr-10 lg:flex-1">
                    {displayListings()}
                    <div class="mt-6">
                        <Pagination
                            itemsPerPage={3}
                            count={count}
                            visitPage={visitPage}
                            previous={previous_number}
                            next={next_number}
                            active={active}
                            setActive={setActive}
                        />
                    </div>
                </div>

            </section>
        </div>






        <Add />
        <GetApp />
    </>
    );
};

export default Listings;
