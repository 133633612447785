import React, { useState, useEffect } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { Link } from "react-router-dom";
import axios from 'axios';

function App() {
  const [listing, setListings] = useState([]);

  // const getdata = async () => {
  //   fetch("https://affanbaba.com/api/listing/get-listings")
  //     .then((res) => res.json())
  //     .then((data) => {
  //       setListing(data.listings);
  //     });
  // };
  // useEffect(() => {
  //   getdata();
  // }, []);


  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/realtors/`);

        setListings(res.data.results);
        // setCount(res.data.count);
        // setPrevious(res.data.previous);
        // setNext(res.data.next);
      }
      catch (err) {

      }
    }

    fetchData();
  }, []);




  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 450;
  };

  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 450;
  };

  return (
    <>
      <table>
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "10%" }}>
          &nbsp;
        </td>
        <td
          style={{
            verticalAlign: "middle",
            textAlign: "center",
            fontSize: 23,
            fontWeight: 600,
          }}
          rowspan={2}
          class="text-3xl font-bold text-gray-900 dark:text-white"
        >
          <h2 class="mb-2 text-2xl font-extrabold tracking-tight leading-none text-gray-900 md:text-2xl lg:text-2xl dark:text-white">
            {" "}
            Latest Properties
          </h2>
        </td>
        <td style={{ borderBottom: "1px solid #9d7e2e", width: "70%" }}>
          &nbsp;{" "}
        </td>

        <div class="border-2 border-[#9d7e2e] px-8 py-3 text-[#9d7e2e] rounded-lg whitespace-nowrap font-medium text-lg hover:border-black hover:text-black ">
          <button class="uppercase">view all</button>
        </div>
      </table>

      <div className="flex items-center">
        <MdChevronLeft
          className="w-[1500px] lg:opacity-50 lg:w-full cursor-pointer hover:opacity-100"
          onClick={slideLeft}
          size={40}
        />
        <div
          id="slider"
          className="relative flex items-center overflow-x-scroll scroll-smooth"
        >
          <section>
            <div className="flex mb-8 mt-10 items-center justify-center">
              {listing.map((house) => (
                <Link to={`/listings/${house.slug}`}>
                  {/* <div className="px-1 max-w-6xl z-50"> */}
                  <div className="lg:[&>div:hover]:scale-95 w-[440px] lg:w-[420px]">
                    <div className="group relative h-96 cursor-pointer shadow-lg shadow-black/30 transition-all duration-300 mb-8">
                      <img
                        className="h-full object-cover transition-all"
                        src={house.photo_main}
                        alt=""
                      />
                      {/* <div className="lg:invisible absolute inset-0 bg-gradient-to-b from-green-500/20 to-black group-hover:visible"> */}
                      <div className="lg:visible absolute inset-0 bg-gradient-to-b from-green-500/20 to-black group-hover:visible">
                        <p className=" bg-[#9d7e2e] p-1 w-[30%] text-center text-lg text-white m-2 rounded-lg">
                          {house.home_type}
                        </p>
                        <div className="absolute inset-x-5 bottom-6">
                          <div className="flex text-white shadow-2xl">
                            <svg
                              width={4}
                              height={48}
                              viewBox="0 0 32 32"
                            ></svg>
                            <div>
                              <p className="font-medium text-xl tracking-wider uppercase text-gray-100">
                                {" "}
                                {house.title}
                              </p>
                              <p className="font-medium text-xl tracking-wider uppercase text-gray-100">
                                {house.state}
                              </p>
                              {/* <p className="text-gray-300 flex font-light tracking-wider"> <span className="pt-1.5 pr-1"><img src="./Assets/Media/location_grey.png" alt="" /></span>
                                  PKR  {house.price}, </p> */}
                            </div>
                          </div>
                          {/* <div className="flex justify-start gap-3 text-gray-200 mt-2 brightness-[400%]">
                              <svg width={22} height={22} viewBox>
                              </svg><img src="./Assets/Media/12-layers.png" alt="" />
                            </div> */}
                          <div className="flex justify-between items-center mt-4">
                            <div>
                              <p className="flex bg-[#9d7e2e] text-white h-8 w-28 duration-300 pt-1 pl-2 rounded hover:bg-[#60a1f6]">
                                Contact Seller
                              </p>
                            </div>
                            <div className="price text-[#9d7e2e] font-bold hover:text-white duration-300 underline underline-offset-4">
                              <p> PKR {house.price}, </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </Link>
              ))}
            </div>
          </section>
        </div>
        <MdChevronRight
          className="w-[1500px] lg:opacity-50 lg:w-full cursor-pointer hover:opacity-100"
          onClick={slideRight}
          size={40}
        />
      </div>
    </>
  );
}

export default App;
