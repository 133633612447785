// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { connect } from "react-redux";
// import { setAlert } from "./../actions/alert";
// import { TailSpin } from "react-loader-spinner";
// import PropTypes from "prop-types";
// import { useHistory } from "react-router-dom";

// const Contact = ({ setAlert }) => {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const history = useHistory();
//     const [formData, setFormData] = useState({
//         name: "",
//         slug: "",
//         agencyname: "",
//         photo: "",
//         agencylogo: "",
//         description: "",
//         saller_type: "Agency",
//         phone: "",
//         agencyphone: "",
//         agencyemail: "",
//         agentscount: "",
//         top_seller: false,
//         date_hired: "",
//         is_published: false,
//     });

//     const {
//         name,
//         slug,
//         agencyname,
//         photo,
//         agencylogo,
//         description,
//         saller_type,
//         phone,
//         agencyphone,
//         agencyemail,
//         agentscount,
//         top_seller,
//         date_hired,
//         is_published,
//     } = formData;

//     const [loading, setLoading] = useState(false);

//     const onChange = (e) =>
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     console.log(localStorage.getItem("token"));

//     const onSubmit = (e) => {
//         e.preventDefault();

//         const config = {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${localStorage.getItem("token")}`,
//             },
//         };

//         setLoading(true);
//         axios
//             .post(
//                 `https://affanbaba.com/api/realtors/CreateAgent`,
//                 {
//                     name,
//                     slug,
//                     agencyname,
//                     photo,
//                     agencylogo,
//                     description,
//                     saller_type,
//                     phone,
//                     agencyphone,
//                     agencyemail,
//                     agentscount,
//                     top_seller,
//                     date_hired,
//                     is_published,
//                 },
//                 config
//             )
//             .then((res) => {
//                 setAlert("Your Add will be live in 24 Hours Sent", "success");
//                 setLoading(false);
//                 window.scrollTo(0, 0);
//                 history.push('/'); // redirect to home page
//             })
//             .catch((err) => {
//                 setAlert("Error with Posting Property", "error");
//                 setLoading(false);
//                 window.scrollTo(0, 0);
//             });
//     };
//     return (
//         <div class="container px-6 py-12 h-full">
//             <header class="text-center ml-6 font-bold uppercase text-lg">
//                 <h1
//                     class=" capitalize
//         inline-block
//         relative
//         cursor-pointer
//         transition-all
//         duration-500
//         before:content-['']
//         before:absolute
//         before:-bottom-2
//         before:left-0
//         before:w-0
//         before:h-1.5
//         before:rounded-full
//         before:opacity-0
//         before:transition-all
//         before:duration-500
//         before:bg-gradient-to-r
//         before:from-blue-600
//         before:via-slate-400
//         before:to-zinc-500
//         hover:before:w-full
//         hover:before:opacity-100"
//                 >
//                     <span class="relative font-bold tracking-wider">Add Realtors</span>
//                 </h1>
//             </header>

//             <form className="listingform" onSubmit={(e) => onSubmit(e)}>
//                 <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <div>
//                             <label
//                                 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                                 htmlFor="saller_type"
//                             >
//                                 Saller Type
//                             </label>
//                             <select
//                                 className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
//                                 name="saller_type"
//                                 onChange={(e) => onChange(e)}
//                                 value={saller_type}
//                             >
//                                 <option>Agency</option>
//                                 <option>Individual</option>
//                             </select>
//                         </div>
//                     </div>
//                     {saller_type === "Agency" && (
//                         <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                             <label
//                                 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                                 htmlFor="agentscount"
//                             >
//                                 How Many agents are working
//                             </label>
//                             <select
//                                 className="block w-full bg-gray-100 border border-gray-300 text-gray-700 py-3 px-4 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//                                 name="agentscount"
//                                 onChange={(e) => onChange(e)}
//                                 value={agentscount}
//                             >
//                                 <option>0</option>
//                                 <option>1</option>
//                                 <option>2</option>
//                                 <option>3</option>
//                                 <option>4</option>
//                                 <option>5</option>
//                                 <option>6</option>
//                                 <option>7</option>
//                                 <option>8</option>
//                                 <option>9</option>
//                                 <option>10</option>
//                                 <option>11</option>
//                                 <option>12</option>
//                                 <option>13</option>
//                                 <option>14</option>
//                                 <option>15</option>
//                                 <option>16</option>
//                                 <option>17</option>
//                                 <option>18</option>
//                                 <option>19</option>
//                                 <option>20</option>
//                             </select>
//                         </div>
//                     )}
//                 </div>
//                 <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             Name
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="name"
//                             type="text"
//                             onChange={onChange}
//                             value={name}
//                             id="name"
//                         />
//                     </div>
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             User
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="slug"
//                             type="text"
//                             onChange={onChange}
//                             value={slug}
//                             id="name"
//                         />
//                     </div>
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             Agency Name
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="agencyname"
//                             type="text"
//                             onChange={onChange}
//                             value={agencyname}
//                             id="name"
//                         />
//                     </div>
//                 </div>
//                 <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             Phone
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="phone"
//                             type="text"
//                             onChange={onChange}
//                             value={phone}
//                             id="name"
//                         />
//                     </div>
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             Agency Phone
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="agencyphone"
//                             type="text"
//                             onChange={onChange}
//                             value={agencyphone}
//                             id="name"
//                         />
//                     </div>
//                     <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                             htmlFor="name"
//                         >
//                             agency email
//                         </label>
//                         <input
//                             className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="agencyemail"
//                             type="text"
//                             onChange={onChange}
//                             value={agencyemail}
//                             id="name"
//                         />
//                     </div>
//                 </div>

//                 {/* <div className="flex flex-col md:flex-row md:justify-between md:mt-3">

//                     <div className="text-left">
//                         <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Description</h5>
//                     </div>


//                     <textarea
//                         className="resize-none w-full h-24 px-4 py-2 text-sm peer bg-gray-200 rounded-lg outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white"
//                         name="description"
//                         onChange={onChange}
//                         value={description}
//                         id="description"
//                     ></textarea>
//                 </div> */}

//                 <div className="w-full px-3 mb-6 md:mb-0 mx-auto md:mt-3">
//                     <div>
//                         <div className="text-left">
//                             <label
//                                 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
//                                 for="grid-state"
//                                 htmlFor="price"
//                             >
//                                 Description
//                             </label>
//                         </div>
//                         <input
//                             className="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
//                             name="description"
//                             type="text-area"
//                             onChange={(e) => onChange(e)}
//                             value={description}
//                         />
//                     </div>
//                 </div>

//                 <div className="flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-4 mt-10">
//                     <div className="border-2 h-64 w-96 flex justify-center items-center mx-auto md:mt-0 mt-5">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-3xl ml-4 font-bold mb-4"
//                             htmlFor="photo"
//                         >
//                             Photo
//                         </label>
//                         <input
//                             className="w-full h-10 px-4 text-sm peer rounded outline-1 outline-[#60a1f6]"
//                             name="photo"
//                             type="file"
//                             onChange={onChange}
//                             value={photo}
//                             id="photo"
//                         />
//                     </div>
//                     <div className="border-2 h-64 w-96 flex justify-center items-center mx-auto md:mt-0 mt-5">
//                         <label
//                             className="block uppercase tracking-wide text-gray-700 text-3xl ml-4 font-bold mb-4"
//                             htmlFor="agencylogo"
//                         >
//                             Agency Logo
//                         </label>
//                         <input
//                             className="w-full h-10 px-4 text-sm peer rounded outline-1 outline-[#60a1f6]"
//                             name="agencylogo"
//                             type="file"
//                             onChange={onChange}
//                             value={agencylogo}
//                             id="agencylogo"
//                         />
//                     </div>
//                 </div>

//                 <div class="flex justify-center mt-5">
//                     {loading ? (
//                         <div class="relative inline-flex items-center justify-center px-12 py-5 overflow-hidden font-medium tracking-tighter text-white bg-[#9d7e2e] rounded-lg group">
//                             <TailSpin type="Oval" color="#424242" height={50} width={50} />
//                         </div>
//                     ) : (
//                         <button class="flex">
//                             <a
//                                 href="#_"
//                                 class="relative inline-flex items-center justify-center px-12 py-5 overflow-hidden font-medium tracking-tighter text-white bg-[#9d7e2e] rounded-lg group"
//                             >
//                                 <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-[#60a1f6] rounded-full group-hover:w-56 group-hover:h-56"></span>
//                                 <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
//                                 <span class="relative font-bold tracking-wider">Publish</span>
//                             </a>
//                         </button>
//                     )}
//                 </div>
//             </form>
//         </div>
//     );
// };

// Contact.propTypes = {
//     setAlert: PropTypes.func.isRequired,
// };

// export default connect(null, { setAlert })(Contact);



import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { setAlert } from "./../actions/alert";
import { TailSpin } from "react-loader-spinner";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const Contact = ({ setAlert }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();
    const [formData, setFormData] = useState({
        name: "",
        slug: "",
        agencyname: "",
        photo: "",
        agencylogo: "",
        description: "",
        saller_type: "Agency",
        phone: "",
        agencyphone: "",
        agencyemail: "",
        agentscount: "",
        top_seller: false,
        date_hired: "",
        is_published: false,
    });

    const {
        name,
        slug,
        agencyname,
        photo,
        agencylogo,
        description,
        saller_type,
        phone,
        agencyphone,
        agencyemail,
        agentscount,
        top_seller,
        date_hired,
        is_published,
    } = formData;

    const [loading, setLoading] = useState(false);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(localStorage.getItem("token"));

    const onSubmit = (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };

        setLoading(true);
        axios
            .post(
                `https://affanbaba.com/api/realtors/CreateAgent`,
                {
                    name,
                    slug,
                    agencyname,
                    photo,
                    agencylogo,
                    description,
                    saller_type,
                    phone,
                    agencyphone,
                    agencyemail,
                    agentscount,
                    top_seller,
                    date_hired,
                    is_published,
                },
                config
            )
            .then((res) => {
                setAlert("Your Agent info will be live in 24 Hours Sent", "success");
                setLoading(false);
                window.scrollTo(0, 0);
                history.push('/'); // redirect to home page
            })
            .catch((err) => {
                setAlert("Error with Posting Property", "error");
                setLoading(false);
                window.scrollTo(0, 0);
            });
    };


    return <>
        <div class="container px-6 py-12 h-full">

            <header class="text-center ml-6 font-bold uppercase text-lg">              <h1
                class=" capitalize
        inline-block
        relative
        cursor-pointer
        transition-all
        duration-500
        before:content-['']
        before:absolute
        before:-bottom-2
        before:left-0
        before:w-0
        before:h-1.5
        before:rounded-full
        before:opacity-0
        before:transition-all
        before:duration-500
        before:bg-gradient-to-r
        before:from-blue-600
        before:via-slate-400
        before:to-zinc-500
        hover:before:w-full
        hover:before:opacity-100"
            >
                <span class="relative font-bold tracking-wider">Add Realtors</span>
            </h1>
            </header>

            <form className="listingform" onSubmit={(e) => onSubmit(e)}>
                <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <div>
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="saller_type"
                            >
                                Saller Type
                            </label>
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-4 px-4 pr-8 rounded leading-tight focus outline-1:outline-none focus:bg-white focus:border-gray-500"
                                name="saller_type"
                                onChange={(e) => onChange(e)}
                                value={saller_type}
                            >
                                <option>Agency</option>
                                <option>Individual</option>
                            </select>
                        </div>
                    </div>
                    {saller_type === "Agency" && (
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                htmlFor="agentscount"
                            >
                                How Many agents are working
                            </label>
                            <select
                                className="block w-full bg-gray-100 border border-gray-300 text-gray-700 py-3 px-4 rounded-lg leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                name="agentscount"
                                onChange={(e) => onChange(e)}
                                value={agentscount}
                            >
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                                <option>13</option>
                                <option>14</option>
                                <option>15</option>
                                <option>16</option>
                                <option>17</option>
                                <option>18</option>
                                <option>19</option>
                                <option>20</option>
                            </select>
                        </div>
                    )}
                </div>
                <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            Name
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="name"
                            type="text"
                            onChange={onChange}
                            value={name}
                            id="name"
                        />
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            User
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="slug"
                            type="text"
                            onChange={onChange}
                            value={slug}
                            id="name"
                        />
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            Agency Name
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="agencyname"
                            type="text"
                            onChange={onChange}
                            value={agencyname}
                            id="name"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row md:justify-between md:mt-3">
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            Phone
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="phone"
                            type="text"
                            onChange={onChange}
                            value={phone}
                            id="name"
                        />
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            Agency Phone
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="agencyphone"
                            type="text"
                            onChange={onChange}
                            value={agencyphone}
                            id="name"
                        />
                    </div>
                    <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            htmlFor="name"
                        >
                            agency email
                        </label>
                        <input
                            className="w-full h-14 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="agencyemail"
                            type="text"
                            onChange={onChange}
                            value={agencyemail}
                            id="name"
                        />
                    </div>
                </div>

                {/* <div className="flex flex-col md:flex-row md:justify-between md:mt-3">

                    <div className="text-left">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Description</h5>
                    </div>


                    <textarea
                        className="resize-none w-full h-24 px-4 py-2 text-sm peer bg-gray-200 rounded-lg outline-none focus:ring-2 focus:ring-blue-500 focus:bg-white"
                        name="description"
                        onChange={onChange}
                        value={description}
                        id="description"
                    ></textarea>
                </div> */}

                <div className="w-full px-3 mb-6 md:mb-0 mx-auto md:mt-3">
                    <div>
                        <div className="text-left">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                                for="grid-state"
                                htmlFor="price"
                            >
                                Description
                            </label>
                        </div>
                        <input
                            className="w-full h-24 px-4 text-sm peer bg-gray-200 rounded outline-1 outline-[#60a1f6]"
                            name="description"
                            type="text-area"
                            onChange={(e) => onChange(e)}
                            value={description}
                        />
                    </div>
                </div>

                <div className="flex flex-col md:flex-row justify-center items-center space-x-0 md:space-x-4 mt-10">
                    <div className="border-2 h-64 w-96 flex justify-center items-center mx-auto md:mt-0 mt-5">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-3xl ml-4 font-bold mb-4"
                            htmlFor="photo"
                        >
                            Photo
                        </label>
                        <input
                            className="w-full h-10 px-4 text-sm peer rounded outline-1 outline-[#60a1f6]"
                            name="photo"
                            type="file"
                            onChange={onChange}
                            value={photo}
                            id="photo"
                        />
                    </div>
                    <div className="border-2 h-64 w-96 flex justify-center items-center mx-auto md:mt-0 mt-5">
                        <label
                            className="block uppercase tracking-wide text-gray-700 text-3xl ml-4 font-bold mb-4"
                            htmlFor="agencylogo"
                        >
                            Agency Logo
                        </label>
                        <input
                            className="w-full h-10 px-4 text-sm peer rounded outline-1 outline-[#60a1f6]"
                            name="agencylogo"
                            type="file"
                            onChange={onChange}
                            value={agencylogo}
                            id="agencylogo"
                        />
                    </div>
                </div>

                <div class="flex justify-center mt-5">

                    <button class="flex">
                        <input type="submit" value="Publish" class="relative inline-flex items-center justify-center px-12 py-5 overflow-hidden font-medium tracking-tighter text-white bg-[#9d7e2e] rounded-lg group" />
                    </button>

                </div>
            </form>
        </div>
    </>;
};

Contact.propTypes = {
    setAlert: PropTypes.func.isRequired,
};

export default connect(null, { setAlert })(Contact);


