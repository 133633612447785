import React from 'react';
import Add from "../components/AddOne";
import GetApp from "../components/GetApp";

const PrivacyPolicy = () => {
    return (
        <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
            <div className="relative py-6 sm:max-w-4xl sm:mx-auto">
                <div className="absolute inset-0 bg-[#9d7e2e] from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-3 sm:rounded-3xl"></div>
                <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                    <div className="max-w-2xl mx-auto text-center">
                        <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">About Affanbaba </h2>
                    </div>
                    <h1 className="text-lg font-semibold mb-3"></h1>
                    {/* <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2 mx-auto max-w-xl"> */}

                    <p className="text-gray-700 mb-4">
                        Affanbaba.com is a full-service real estate company specializing in the purchase, sale, and development of residential and commercial properties. We have an experienced team of real estate professionals who are dedicated to providing our clients with the highest level of service.
                    </p>
                    <p className="text-gray-700 mb-4">
                        Affanbaba.com is the ultimate online and mobile destination for real estate. Whether you're a realtor looking for new clients or a user searching for your dream home, Affanbaba.com has everything you need. We're committed to making it easy for everyone to find what they need, and our one-window Solution means that users can also find shops and services for all their home needs.
                    </p>
                    <p className="text-gray-700 mb-4">
                        Our company's mission is to provide our clients with the best possible real estate experience. We strive to exceed our clients' expectations by providing expert advice, personalized service, and a commitment to finding the perfect property for each client. Our team has a wealth of knowledge and experience in all aspects of real estate, including financing, zoning, construction, and marketing. We are confident that we can find the perfect property for each of our clients' individual needs.
                    </p>
                    <p className="text-gray-700 mb-4">
                        If you are interested in purchasing or selling a property, please contact us today. We would be happy to assist you through every step of the process. . Connect with us today to get started!.
                    </p>

                    {/* </div> */}


                </div>
            </div>
            <Add />
            <GetApp />
        </div>
    );
}

export default PrivacyPolicy;
