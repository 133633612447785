import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const card = (props) => {
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    // border-b-2 border-solid border-[#9d7e2e]

    // <div className="max-w-sm rounded overflow-hidden shadow-lg">
    //   <img className="w-full" src={props.main_photo} alt={props.title} />
    //   <div className="px-6 py-4">
    //     <div className="mb-2">
    //       <button className="px-2 py-1 text-sm text-white bg-yellow-500 rounded">
    //         {props.sale_type}
    //       </button>
    //     </div>
    //     <div className="mb-2">
    //       <h2 className="text-lg font-bold">{props.title}</h2>
    //     </div>
    //     <hr />
    //     <div className="mt-2">
    //       <p className="text-sm font-medium text-gray-600">
    //         <span className="inline-block mr-2">{props.bedrooms} Beds</span>
    //         <span className="inline-block mr-2">{props.bathrooms} Baths</span>
    //         <span className="inline-block">{props.sqft} {props.unit_type}</span>
    //       </p>
    //     </div>
    //     <div className="mt-2">
    //       <p className="text-lg font-bold">PKR.{numberWithCommas(props.price)}</p>
    //     </div>
    //     <div className="mt-4">
    //       <Link to={`/listings/${props.slug}`}>
    //         <button className="px-4 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-md uppercase tracking-wider">
    //           View {props.home_type}
    //         </button>
    //       </Link>
    //     </div>
    //   </div>
    // </div>

    <div class="flex flex-col md:flex-row bg-white bg-opacity-75 rounded-lg overflow-hidden">

      <div class="relative w-full md:w-64 lg:w-100 h-64 md:h-auto">
        <Link to={`/listings/${props.slug}`}>
          <div
            className="group relative h-96 w-[25.6rem] lg:w-1000 cursor-pointer overflow-hidden shadow-lg shadow-black/30 transition-all duration-200 mb-8"
            style={{
              backgroundImage: `url(${props.main_photo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div class="absolute top-0 left-0 p-2 bg-white bg-opacity-75">
              <button class="px-2 py-1 text-sm text-white bg-[#9d7e2e] rounded">
                {props.sale_type}
              </button>
            </div>
            <div class="absolute bottom-0 left-0 right-0 p-4 bg-white bg-opacity-75">
              <div className="mb-2">
                <h2 className="text-lg font-bold"></h2>
              </div>
              <div class="price text-[#9d7e2e] font-bold pl-3 underline underline-offset-4">
                <p>PKR.{numberWithCommas(props.price)}</p>
              </div>
              <hr />
              <div className="mt-2">
                <p className="text-sm font-medium text-gray-600 flex items-center">
                  <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>
                    {props.title}  {props.sale_type}
                  </span>
                </p>
              </div>
              <button class="px-4 py-2 text-white bg-[#9d7e2e] hover:bg-blue-600 rounded-md uppercase tracking-wider">
                View {props.home_type}
              </button>

            </div>

          </div>
        </Link>
      </div>
    </div >

    // <div class="products-area space-y-8 flex-wrap w-full">
    //   <div class="first-col flex space-x-3 flex-wrap md:space-y-0 space-y-5 justify-center items-center">
    //     <div className="group relative h-96 w-[25.6rem] lg:w-1000 cursor-pointer overflow-hidden shadow-lg shadow-black/30 transition-all duration-200 mb-8" style={{ backgroundImage: `url(${props.main_photo})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    //       <div className="px-6 py-4 bg-white bg-opacity-75">
    //         <div className="mb-2">
    //           <button className="px-2 py-1 text-sm text-white bg-[#9d7e2e] rounded">
    //             {props.sale_type}
    //           </button>
    //         </div>

    //       </div>
    //       <div className="mt-4">
    //         <Link to={`/listings/${props.slug}`}>
    //           <button className="px-4 py-2 text-white bg-[#9d7e2e] hover:bg-blue-600 rounded-md uppercase tracking-wider">
    //             View {props.home_type}
    //           </button>
    //         </Link>
    //       </div>

    //     </div>
    //     <div className="px-6 py-4 bg-white bg-opacity-75">
    //       <div class="flex bg-white bg-opacity-75  gap-3 text-white shadow-2xl">

    //         <div>
    //           <p class="font-medium  bg-[#9d7e2e] text-xl tracking-wider text-gray-100">

    //             {props.title} for  {props.sale_type}
    //           </p>
    //           <p class="bg-[#9d7e2e]  text-gray-300 flex font-light tracking-wider"> <span
    //             class="pt-1.5 pr-1"><img src="./Assets/Media/location_grey.png"
    //               alt="" /></span>
    //             {props.sqft}  {props.unit_type}   {props.landarea} {props.city}
    //           </p>
    //         </div>
    //       </div>

    //       <div class="flex justify-start items-center content-center ml-8 mt-4">
    //         <div>
    //           <p
    //             class="flex bg-[#9d7e2e] text-white h-8 w-28 pt-1 pl-2 rounded hover:bg-[#60a1f6]">
    //             Contact Seller</p>
    //         </div>
    //         <div
    //           class="price text-[#9d7e2e] font-bold pl-3 underline underline-offset-4">
    //           <p>PKR.{numberWithCommas(props.price)}</p>
    //         </div>
    //       </div>
    //     </div>

    //     {/* <div className="mb-2">
    //     <h2 className="text-lg font-bold"></h2>
    //   </div>
    //   <hr />
    //   <div className="mt-2">
    //     <p className="text-sm font-medium text-gray-600">
    //       <span className="inline-block mr-2">{props.bedrooms} Beds</span>
    //       <span className="inline-block mr-2">{props.bathrooms} Baths</span>
    //       <span className="inline-block">{props.sqft} {props.unit_type}</span>
    //     </p>
    //   </div>
    //   <div className="mt-2">
    //     <p className="text-lg font-bold"></p>
    //   </div>
    //  */}
    //   </div>
    // </div>
  );
};

card.propTypes = {
  title: PropTypes.string.isRequired,
  main_photo: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  bedrooms: PropTypes.number.isRequired,
  bathrooms: PropTypes.string.isRequired,
  sale_type: PropTypes.string.isRequired,
  home_type: PropTypes.string.isRequired,
  unit_type: PropTypes.string.isRequired,
  sqft: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
};

export default card;
