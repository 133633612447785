import React, { useState } from "react";

function SearchComponent({ handleSearch }) {
    const [saleType, setSaleType] = useState("");
    const [price, setPrice] = useState("");
    const [bedrooms, setBedrooms] = useState("");
    const [bathrooms, setBathrooms] = useState("");
    const [homeType, setHomeType] = useState("");
    const [sqft, setSqft] = useState("");
    const [keywords, setKeywords] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const searchData = {
            sale_type: saleType,
            price: price,
            bedrooms: bedrooms,
            bathrooms: bathrooms,
            home_type: homeType,
            sqft: sqft,
            keywords: keywords,
        };
        handleSearch(searchData);
    };

    return (
        <div class="mx-auto max-w-md">
            <form class="bg-[#9d7e2e] shadow-lg rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="saleType">Sale Type:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="saleType"
                        type="text"
                        value={saleType}
                        onChange={(e) => setSaleType(e.target.value)}
                        placeholder="e.g. For Sale, For Rent, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="price">Price:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="price"
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        placeholder="e.g. $1000, $2000/month, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="bedrooms">Bedrooms:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="bedrooms"
                        type="text"
                        value={bedrooms}
                        onChange={(e) => setBedrooms(e.target.value)}
                        placeholder="e.g. 1, 2, 3, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="bathrooms">Bathrooms:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="bathrooms"
                        type="text"
                        value={bathrooms}
                        onChange={(e) => setBathrooms(e.target.value)}
                        placeholder="e.g. 1, 2, 3, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="homeType">Home Type:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="homeType"
                        type="text"
                        value={homeType}
                        onChange={(e) => setHomeType(e.target.value)}
                        placeholder="e.g. House, Apartment, Condo, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="sqft">Count:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="sqft"
                        type="text"
                        value={sqft}
                        onChange={(e) => setSqft(e.target.value)}
                        placeholder="e.g. 1000, 2000, 3000, etc."
                    />
                </div>
                <div class="mb-4">
                    <label class="block text-white font-bold mb-2" for="keywords">Keywords:</label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-white leading-tight focus:outline-none focus:shadow-outline"
                        id="keywords"
                        type="text"
                        value={keywords}
                        onChange={(e) => setKeywords(e.target.value)}
                        placeholder="e.g. plot, apartment, house, etc."
                    />
                </div>
                <div class="flex items-center justify-between">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={handleSearch}>
                        Search
                    </button>
                </div>

            </form>
        </div>
    );
}

export default SearchComponent;
