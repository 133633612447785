
import React from 'react';




function AddOne() {


  return (



    <div class="space-for-ads bg-[#60a1f6] w-full sm:w-[550px] md:w-[700px] lg:w-[900px] h-[150px] text-white uppercase text-center mx-auto mb-8 mt-10 border-2 border-blue-500 leading-[150px] text-lg tracking-wider">
      <p>space available for ads</p>
    </div>




  );
}


export default AddOne;