import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import phone from "./../assets/Media/footer mobile_phones (1).png";
import { login } from "../actions/auth";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };
  if (isAuthenticated) return <Redirect to="/" />;
  return (

    <div className="flex justify-center items-center py-14 bg-gray-200">
      <div
        className="flex flex-wrap items-center justify-center rounded-l-md bg-white"
        style={{ width: "26rem", height: "32rem" }}
      >
        <div className="w-72">
          <div className="text-center">
            <h1 className="font-bold text-3xl text-gray-900 mb-6">Welcome Back</h1>
            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white mb-10">Please Enter your Credentials</h5>
          </div>


          <form className="mt-4" onSubmit={(e) => onSubmit(e)}>
            <div className="mb-3">
              <label className="mb-2 block text-xs font-semibold">
                Email
              </label>
              <input
                placeholder="Enter your email"
                className="w-full rounded-md border border-gray-300 focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-purple-700 py-1 px-1.5 text-gray-500"
                type="email"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="mb-2 block text-xs font-semibold">
                Password
              </label>
              <input
                type="password"
                className="block w-full rounded-md border border-gray-300 focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-purple-700 py-1 px-1.5 text-gray-500"
                placeholder=" Enter your Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                minLength="6"
              />
            </div>
            <div className="mb-7 flex justify-between items-center">
              <div className="flex items-center">
                <input
                  id="remember"
                  type="checkbox"
                  className="form-checkbox h-4 w-4 text-purple-600 transition duration-150 ease-in-out mr-2 checked:bg-purple-700 checked:border-transparent focus:outline-none focus:shadow-outline-purple"
                />
                <label htmlFor="remember" className="text-xs font-semibold text-gray-700">
                  Keep me logged in
                </label>
              </div>
              <a href="#" className="text-xs font-semibold hover:underline text-red-700">
                Forgot your password?
              </a>
            </div>

            <div className="mb-3">
              <button class="mb-1.5 block w-full text-center text-white bg-blue-400 hover:bg-[#9d7e2e] px-4 py-2 rounded-md shadow-lg transition-all duration-300">
                Sign In
              </button>

              {/* <button className="flex flex-wrap justify-center w-full border border-gray-300 hover:border-gray-500 px-2 py-1.5 rounded-md">
                  <img
                    className="w-5 mr-2"
                    src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                  />
                  Sign in with Google
                </button> */}
            </div>
          </form>
          <hr />
          <div className="text-center mb-4 flex justify-center">
            <span className="text-sm text-gray-500 font-semibold mr-2">Don't have an account?</span>

            <Link className="px-4 py-2 bg-[#9d7e2e] hover:bg-red-600 rounded-full text-sm font-semibold text-white" to="/signup">
              Sign up
            </Link>
          </div>

        </div>
      </div>
      <div class="hidden lg:block">
        <div className="">
          <img class="h-[26rem] w-[26rem]" src={phone} alt="" />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
