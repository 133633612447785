import './index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
// import Submitproperty from './containers/Submit_Property';
import Contact from './containers/Contact';
import Listings from './containers/Listings';
import Realtors from './containers/Realtors';
import Flat from './containers/Flat';
import Plot from './containers/Plot';
import Commercial from './containers/Commercial';
import Projects from './containers/Projects';
import Agents from './containers/Agents';
import Blog from './containers/Blog';
import ListingDetail from './containers/ListingDetail';
import RealtorsDetail from './containers/RealtorsDetail';
import Login from './containers/Login';
import SignUp from './containers/SignUp';
import AddProperty from './containers/Submit_Property';
import AddRealtors from './containers/Submit_Realtors';
import Test from './containers/Test';
import Terms from './containers/Terms';
import Policy from './containers/PrivacyPolicy';
import FAQs from './containers/FAQs';
import NotFound from './components/NotFound';
import Layout from './hocs/Layout';
import PrivateRoute from './components/privateRoute';

import { Provider } from 'react-redux';
import store from './store';

// import './sass/main.scss';

const App = () => (
  <Provider store={store}>
    <Router>
      <Layout>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/about' component={About} />
          {/* <Route exact Submitproperty='/Submitproperty' component={Submitproperty} /> */}
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/listings' component={Listings} />

          <Route exact path='/Flat' component={Flat} />
          <Route exact path='/Plot' component={Plot} />
          <Route exact path='/Commercial' component={Commercial} />
          <Route exact path='/Projects' component={Projects} />
          <Route exact path='/Agents' component={Agents} />
          <Route exact path='/Blog' component={Blog} />
          <PrivateRoute exact path='/AddProperty' component={AddProperty} />
          <PrivateRoute exact path='/AddRealtors' component={AddRealtors} />
          {/* <Route exact path='/listings/:id' component={ListingDetail} /> */}
          <PrivateRoute exact path='/listings/:id' component={ListingDetail} />
          {/* <Route exact path='/listings/:id' component={ListingDetail} /> */}
          <PrivateRoute exact path='/realtors/:id' component={RealtorsDetail} />
          <Route exact path='/Realtors' component={Realtors} />

          <Route exact path='/login' component={Login} />
          <Route exact path='/Test' component={Test} />
          <Route exact path='/Terms' component={Terms} />
          <Route exact path='/PrivacyPolicy' component={Policy} />
          <Route exact path='/Faqs' component={FAQs} />
          <Route exact path='/signup' component={SignUp} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  </Provider>
);

export default App;
