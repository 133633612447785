import React, { useState, useEffect } from "react";
import GetApp from "../components/GetApp";
import Add from "../components/AddOne";
import { Link, NavLink } from "react-router-dom";

function RealtorsPage() {
  const [realtors, setRealtors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetch("https://affanbaba.com/api/realtors/")
      .then((response) => response.json())
      .then((data) => setRealtors(data));
  }, []);

  const filteredRealtors = realtors.filter((realtor) =>
    realtor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4">Real Estate Agents</h1>
        <div className="mb-4">
          <input
            type="text"
            className="border rounded py-2 px-3 w-full pr-10"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredRealtors.map((realtor) => (
            <div key={realtor.slug}>
              <div
                className="bg-white rounded-lg shadow-md overflow-hidden"
                style={{ backgroundImage: `url(${realtor.photo})`, backgroundSize: 'cover', height: '24rem' }}
              >
              </div>
              <div
                key={`details-${realtor.slug}`}
                className="bg-white rounded-lg shadow-md overflow-hidden"
              >
                <div className="p-4">
                  <h2 className="text-xl font-bold mb-2">{realtor.name}</h2>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <svg className="w-6 h-6 text-[#9d7e2e]-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-[#9d7e2e]-900">{realtor.agencyname}</p>
                      <p className="text-sm text-[#9d7e2e]-500">{realtor.saller_type}</p>
                    </div>
                  </div>
                  <a href={`/realtors/${realtor.id}`} className="bg-[#9d7e2e] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded block w-full text-center">
                    View Profile
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>

      <Add />
      <GetApp /></>
  );
}

export default RealtorsPage;



