import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';
import Alert from './Alert';
import PropTypes from 'prop-types';
import Logo1 from "./../assets/Media/1stnav.png"
import Polygon from "./../assets/Media/Polygon_1.png"
import facebook_logo from "./../assets/Media/facebook_logo.png"
import Twitter_logo_white from "./../assets/Media/Twitter_logo_white.png"
import Instagram_Logo_white from "./../assets/Media/Instagram_Logo_white.png"
import Logodnav from "./../assets/Media/2ndnav.png"
import User_login from "./../assets/Media/User_login.png"

const navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
    const authLinks = (
        <a onClick={logout} href='#!'>Logout</a>
    );
    const guestLinks = (
        <Fragment>
            {/* <div id="right-sec1" className="flex justify-around items-center space-x-11 mr-20">
                    <div id="language" className="cursor-pointer ">
                        <p>ENG\اردو</p>
                    </div>
                    <div id="socials">
                        <ul className="flex space-x-3">
                            <li><img src="./Assets/Media/facebook_logo.png" alt="fb" /></li>
                            <li><img src="./Assets/Media/Twitter_logo_white.png" alt="twitter" /></li>
                            <li><img src="./Assets/Media/Instagram_Logo_white.png" alt="instagram" /></li>
                        </ul>
                    </div>
                </div> */}
            <Link to='/login'>Login</Link>
            {/* <Link className='navbar__top__auth__link' to='/signup'>Sign Up</Link> */}
        </Fragment>
    );

    return (
        <Fragment>
            <nav id="container" class="font-sans overflow-hidden">
                <div id="navbar1" className=" flex justify-between px-[55px] items-center bg-[#9d7e2e] ">
                    <div id="left-sec1" className="">
                        <div className="mt-2 cursor-pointer text-white text-2xl font-bold">
                            <NavLink to="/">  <h1>affanbaba.com</h1> </NavLink>
                        </div>
                        <NavLink to="/"> <span><img src={Polygon} alt="" className="ml-[55px] h-2 mt-1 " /></span>
                        </NavLink>
                    </div>
                    <div id="right-sec1" className="">
                        <ul className="flex space-x-3 w-20 items-center">
                            <li><img src={facebook_logo} alt="fb" /></li>
                            <li><img src={Twitter_logo_white} alt="twitter" /></li>
                            <li><img src={Instagram_Logo_white} alt="instagram" /></li>
                        </ul>
                    </div>
                </div>
                <div id="container" className="font-sans overflow-hidden">
                    <header className="hidden lg:block">
                        <div id="navbar2" className="flex justify-around items-center bg-slate-100 py-1 text-black font-bold text-xl">
                            <div id="left-sec2" className="flex space-x-5">
                                <div id="branding2">
                                    <img className="w-24" src={Logodnav} alt="logo" />
                                </div>
                                <div id="links2">
                                    <ul className="flex justify-around mt-9 space-x-7">
                                        <NavLink to="/Listings">
                                            <li className="capitalize
                                        
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">HOUSE</li>
                                        </NavLink>

                                        <NavLink to="/Plot">
                                            <li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">PLOT</li>
                                        </NavLink>
                                        <NavLink to="/Listings"><li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">FLAT</li>
                                        </NavLink>
                                        <NavLink to="/Commercial">
                                            <li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">COMMERCIAL</li>
                                        </NavLink>
                                        <NavLink to="/Projects">
                                            <li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">PROJECTS</li>
                                        </NavLink>
                                        <NavLink to="/Agents">
                                            <li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">AGENTS</li>
                                        </NavLink>
                                        <NavLink to="/Blog">
                                            <li className="capitalize
                        inline-block
                        relative
                        cursor-pointer
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-0
                        before:w-0
                        before:h-1.5
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-blue-600
                        before:via-slate-400
                        before:to-zinc-500
                        hover:before:w-full
                        hover:before:opacity-100">BLOG</li>
                                        </NavLink>
                                    </ul>
                                </div>
                            </div>
                            <div id="right-sec2" className="text-white font-medium">
                                <div id="buttons" className="flex space-x-4">
                                    <button className="flex bg-blue-900 py-3 px-5 rounded-lg hover:bg-blue-800 active:bg-blue-700">
                                        <img src={User_login} alt="" />
                                        <a className="pl-2" href="#">  {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}</a>
                                    </button>
                                    <button className="flex bg-blue-900 py-3 px-5 rounded-lg hover:bg-blue-800 active:bg-blue-700">
                                        <NavLink to="/AddProperty"> <a href="#">ADDING LISTING</a> </NavLink>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header className="visible lg:hidden">
                        <div className>
                            <header>
                                <div className="relative z-20 border-b bg-white">
                                    <div className="pr-6 pl-2 pt-2">
                                        <div className="flex items-center justify-between">
                                            <div className="relative z-20">
                                                <NavLink to="/"> <img src={Logodnav} alt="affan baba" className="w-32" />     </NavLink>
                                            </div>
                                            <div className="flex items-center justify-end border-l lg:border-l-0">
                                                <input type="checkbox" name="hamburger" id="hamburger" className="peer" hidden />
                                                <label htmlFor="hamburger" className="peer-checked:hamburger block relative z-20 p-6 -mr-6 cursor-pointer lg:hidden">
                                                    <div aria-hidden="true" className="m-auto h-0.5 w-6 rounded bg-[#9d7e2e] transition duration-300" />
                                                    <div aria-hidden="true" className="m-auto mt-2 h-0.5 w-6 rounded bg-[#9d7e2e] transition duration-300">
                                                    </div>
                                                </label>
                                                <div className="peer-checked:translate-x-0 fixed inset-0 w-screen translate-x-[-100%] bg-[#60a1f6] border-r shadow-xl transition duration-300">
                                                    <div className="flex flex-col  justify-between">
                                                        <ul className="pl-6 pt-44 text-white font-medium space-y-8">
                                                            <li>
                                                                <a href="./index.html" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">
                                                                    </span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="./Shop Page/index.html" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black"></span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="./Services/index.html" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black"></span>
                                                                </a>
                                                            </li>
                                                            <div className="flex space-x-5">
                                                                <li>
                                                                    <a href="#"><img src={facebook_logo} alt="" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#">
                                                                        <img src={Twitter_logo_white} alt="" />
                                                                    </a>
                                                                </li>
                                                                <li>
                                                                    <a href="#"><img src={Instagram_Logo_white} alt="" />
                                                                    </a>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                        <ul className="pl-6 pt-10 pb-10 text-white font-medium space-y-8">
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">PLOT</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">HOME</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">COMMERCIAL</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">RENT</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">AGENTS</span>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#" className="group relative before:absolute before:inset-x-0 before:bottom-0 before:h-2 before:origin-right before:scale-x-0 before:bg-yellow-400 before:transition before:duration-200 hover:before:origin-left hover:before:scale-x-100">
                                                                    <span className="relative group-hover:text-black">BLOG</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <div className="border-t pt-4 px-6">
                                                            <a className="block px-6 py-3 rounded-full bg-gradient-to-r from-[#9d7e2e] to-blue-900 text-center text-white" href="#">  {!loading && (<Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>)}</a>
                                                        </div>
                                                        <div className=" py-8 px-6 ">
                                                            <a href="#" className="block px-6 py-3 rounded-full bg-gradient-to-r from-[#9d7e2e] to-blue-900 text-center text-white">
                                                                ADD LISTING
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                        </div>
                    </header>
                </div>
            </nav>
            <Alert />
        </Fragment>
    );
};

navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { logout })(navbar);
